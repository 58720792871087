.btn1 {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color_white;
    padding: 19px 57px 16px 30px;
    background-color: $color1;
    background-repeat: no-repeat;
    background-position: right 30px center;
    text-transform: uppercase;
    border: 0;
    background-image: url("./../img/more_arr.svg");
    font-family: $font-family;
    position: relative;
    @include anim;
    &:focus{

        color: $color_white;
        text-decoration: none !important;
    }
    &:hover{
        background-color: $color2;
        color: $color_white;
        text-decoration: none !important;
        &:after{
            transform: translate(5px, 5px);
            background-color: $color1;
        }
    }
    &:after{
        background-color: transparent;
        z-index: -1;
        content: '';
        @include fill;
        @include anim;
    }
    &.type2{
        background-color: transparent;
        border: 2px solid $color_white;
        &:hover{
            border-color: $color2;
            background-color: $color2;
        }
    }
    &.return_btn{
        padding-right: 30px;
        padding-left: 57px;
        background-position: left 30px center;
        background-image: url("./../img/pagi_arr_left.svg");
    }
    &:hover, &.hover {
        text-decoration: none;
        color:$color_white;

        //-webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        //-moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        //box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
    }
}
