@import "main/variables";

& {
    font-family: $font-family;
    font-size: 14px;
    line-height: 21px;
    color: $color_font2;
    @media only screen and (max-width: 1200px) {
        font-size: 13px;
        line-height: 20px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
    }
}

h1 {
    font-size: 48px;
    line-height: 55px;
    @media only screen and (max-width: 1200px) {
        font-size: 38px;
        line-height: 45px;
    }
    @media only screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 36px;
    }
}

h2 {
    font-size: 38px;
    line-height: 46px;
    @media only screen and (max-width: 1200px) {
        font-size: 30px;
        line-height: 38px;
    }
    @media only screen and (max-width: 320px) {
        font-size: 24px;
        line-height: 30px;
    }
}

h3 {
    font-size: 30px;
    line-height: 35px;
    @media only screen and (max-width: 1200px) {
        font-size: 26px;
        line-height: 29px;
    }
}

h4 {
    font-size: 24px;
    line-height: 30px;
    @media only screen and (max-width: 1200px) {
        font-size: 20px;
        line-height: 26px;
    }
}

h5 {
    font-size: 20px;
    line-height: 26px;
}

h6 {
    font-size: 17px;
    line-height: 23px;
}

h1,h2,h3,h4,h5,h6,p,ul,ol,a,img{
    margin-bottom: 30px;
    &:last-child{
        margin-bottom: 0;
    }
}

h1,h2,h3,h4,h5,h6{
    color: $color_font1;
}

.err_txt {
    color:$color_err;
}

ol{
    padding-left: 18px;
}

> ul{
    padding-left: 18px;
    list-style: disc;
    > li{
        color: $color_font2 !important;
        *{
            color: $color_font2 !important;
        }
        //margin-left: -20px;
        //margin-bottom: 20px;
        //h4{
        //    vertical-align: top;
        //}
        &:last-child{
            margin-bottom: 0;
        }
        //p,h1,h2,h3,h4,h5,h6{
        //    display: inline-block;
        //    width: calc(100% - 40px);
        //    margin-left: -4px;
        //}
        //&:before{
        //    //margin-right: 10px;
        //    content: '';
        //    width: 6px;
        //    height: 6px;
        //    background: $color1;
        //    border-radius: 2px;
        //    transform: rotate(45deg);
        //    display: inline-block;
        ////    vertical-align: top;
        //    position: relative;
        //    top: -2px;
        //    //top: 1rem;
        //    transform: translate(-10px, 0) rotate(45deg);
        //}
    }
}

a{
    text-decoration: underline;
    color: $color1;
}
