$font-family: 'Myriad Pro';

$color1: #005BAB;
$color2: #002F66;
$color3: #f7f8fa;


$color_white:#ffffff;
$color_black:#000000;

$color_font1:#0D161D;
$color_font2:#555658;

// musi być zawsze
$border-color:#555658;
// musi być zawsze
$color_err: #D00B01;

@mixin fill{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin default_image{
    background-image: url("./../img/no_photos.svg");
    background-color: #EEEEEE;
}

@mixin anim{
    transition: 0.3s all ease;
}

@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}

@mixin vertical{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin content_width{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}
