.new-cp {
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    padding:40px;
    background-color:#ffffff;
    color:#000;
    font-size:14px;
    z-index: 10;
    border-top:2px solid #aaaaaa;

    svg {
        width:100px;
        height:100px;
        path {
            fill:#aaaaaa;
        }
    }

    h3, p {
        padding-bottom:15px;
    }


    button {
        border:1px solid #aaaaaa;
        border-radius:5px;
        background:transparent;
        color:#000;
        padding:10px 20px;
        transition: 0.2s ease;

        &:hover {
            background-color:#aaaaaa;
            color:#ffffff;
        }
    }

    > div {
        display:inline-block;
        margin-right: -4px;
        vertical-align: top;
    }

    .new-cp-content {
        padding-left:30px;
        width:calc(100% - 100px);

    }

    button {
        margin-right: 10px;
    }

    .new-cp-content-settings-options-one-cookie {
        cursor: pointer;
        transition: 0.2s ease;

        &:hover {
            opacity:0.6;
        }

        > div {
            display:inline-block;
            margin-right: -4px;
            vertical-align: top;

            &.new-cp-content-settings-options-one-cookie-checkbox {
                width:20px;
                margin-right: 10px;

                > div {
                    border:1px solid #aaaaaa;
                    border-radius: 50%;
                    width:20px;
                    height:20px;
                    position:relative;
                }
            }

            &.new-cp-content-settings-options-one-cookie-desc {
                width:calc(100% - 30px);
                padding-top:2px;
            }
        }

        &.on {
            .new-cp-content-settings-options-one-cookie-checkbox {
                > div {
                    &:after {
                        content:"";
                        position:absolute;
                        left:2px;
                        top:2px;
                        width:14px;
                        height:14px;
                        background-color:#aaaaaa;
                        border-radius: 50%;
                    }
                }
            }
        }

        &.disabled {
            .new-cp-content-settings-options-one-cookie-checkbox {
                > div {
                    opacity:0.2;
                }
            }
        }
    }
}
