@import "main/variables";

$form-border-radius: 5px;

input[type="text"], [type="number"], input[type="password"] {
    padding: 0 15px;
    height: 55px;
    border: 1px solid #D4D7DD;
    background-color: $color3;
    font-size: 14px;
    color: $color_font1;
    width: 100%;
    border-radius: $form-border-radius;
}

textarea{
    font-size: 14px;
    color: $color_font1;
    padding: 10px 15px;
    min-height: 190px;
    border: 1px solid #D4D7DD;
    background-color: $color3;
    resize: none;
    width: 100%;
    border-radius: $form-border-radius;
}

.myform2_tags {
    .multi_list {
        margin:0;
        padding:0;
        list-style-type:none;
        li {
            display:inline-block;
            vertical-align: top;
            border:1px solid $border-color;
            padding:3px 5px;
            border-radius: 3px;
            margin-top:4px;
            margin-right: 4px;

            span {
                position:relative;
                top:3px;
            }
        }
    }
}

.field_checkbox {
    .field_checkbox_box {

        > div {
            display:inline-block;
            vertical-align: top;

            &.field_checkbox_input {
               // padding:13px 10px 0 0;
            }
        }

        .checkbox_image_box {
            cursor:pointer;
            margin-top: 3px;
            > div {
                display:none;

                width:15px;
                height:15px;
                border: 1px solid #D4D7DD;

                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 2px;
                background-color: $color3;
                @include bg_pos;
                background-size: cover;
                &.yes {
                    background-image: url("./../img/check_icon.png");
                }

                &.on {
                    display:block;
                }
            }
        }

        .field_checkbox_subtitle {
            padding-top:10px;
        }
    }
}

.field_radio {
    font-size: 14px;
    line-height: 21px;
    .field_radio_box {
        > div {
            display:inline-block;
            vertical-align: top;

            &.field_radio_input {
               // padding:13px 10px 0 0;
                padding-right: 10px;
            }
        }
        .radio_image_box {
            cursor:pointer;

            width:15px;
            height:15px;
            border:1px solid #D4D7DD;
            overflow: hidden;
            position:relative;
            background-color:$color3;
            border-radius: 50%;

            > div {
                display:none;

                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;

                &.on {
                    display:block;
                    background-color: $color1;
                }
            }
        }

        .field_radio_subtitle {
            //padding-top:10px;
        }
    }

    .field_radio_handler {
        display:inline-block;
        vertical-align: top;
        padding-right: 30px;
    }
}

.myform2_dropzone_box {
    padding:0 20px;
    border:1px solid #e3d4c0;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    .dropzone_box {
        padding:20px 0;
        cursor: pointer;
        color: #ADAAA2;
        background: url('/img/dropzone_ico.png') right center no-repeat;
        background-size: 30px auto;
    }

    .upload_progres_bars {
        width:100%;

        > div {
            width:100%;

            &:last-child {
                padding-bottom:10px;
            }

            > div {
                display:inline-block;

                &.dz-filename {
                    width: calc(100% - 100px);
                }

                &.dz-size {
                    width: 96px;
                }

                &.dz-progress {
                    width: 100%;
                    height: 10px;
                    background-color: #e3dedb;
                    position: relative;

                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;

                    .dz-upload {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-color: #e3d4c0;

                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    .previewsContainer {
        .row {
            margin-left:0;
            margin-right: 0;
            padding-bottom:10px;

            &:first-child {
                padding-top:10px;
            }

            > div {
                display:inline-block;

                &.lp {
                    display:none;
                }

                &.thumb {
                    width:70px;

                    img {
                        width:50px;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }

                &.name {
                    width: calc(100% - 120px);
                }

                &.action {
                    width:50px;
                    text-align: right;

                    .download {
                        display:none;
                    }

                    .delete {
                        cursor: pointer;
                        position:relative;
                        top:2px;

                        &:hover {
                            opacity:0.7;
                        }
                    }
                }
            }
        }
    }
}

