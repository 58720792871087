@import "main/importer";

.default_font { @import "main/default_font"; }
.default_form { @import "main/default_form"; }

@import "../js/plugins/select2/select2";

body{
    font-family: $font-family;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        font-weight: 400;
        margin: 0;
    }
    ul{
        list-style: none;
        padding-left: 0;
    }
    strong{
        font-weight: 600;
    }
    &.scroll_block{
        overflow: hidden;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    &.page_contrast{
        .renovation .renovation_desc .desc_side > div .desc, .story_box > div.desc_holder > div .desc{
            background: rgb(238, 238, 238);
        }
        section.top{
            .recruitment_contact.white .default_font .lead_desc{
                color: $color_font1;
                a{
                    color: $color_font1;
                }
            }
            .default_font.adress.white{
                color: $color_font1;
            }
            .date{
                &.white{
                    color: $color_font1;
                }
            }
        }
        .pagi_box .myajaxlist_paginate_box{
            background: transparent;
        }
        .rwd_search_box.desctop_search_box{
            background: rgb(238, 238, 238);
        }
        section.top.with_image .breadcrumbs > ul > li{
            margin-right: 20px;
        }
        section.top.with_image .breadcrumbs > ul > li a:after{
            content: '';
        }
        section.top .top_image .image{
            opacity: 0.1;
        }
        section.top.with_image .breadcrumbs > ul > li a, section.top.with_image h2{
            color: $color_font1 !important;
        }
        section.top {
            .top_image{
                background: $color_white;
            }
        }
        .inner_frame_box{
            &:after{
                background: #eeeeee;
            }
        }
        .year_btn{
            &.active{
                color: $color_white !important;
                *{
                    color: $color_white !important;
                }
                span{
                    color: $color_white !important;
                }
            }
        }
       .scripts_btn{
           background-color: transparent !important;
           color: $color_white !important;
           span{
               color: $color_white !important;
               background: transparent !important;
           }
       }
        .why{
            background-image: none;
            background-color: transparent;
            h1,h2,h3,h4,h5,h6,p{
                color: $color_font1 !important;
            }
        }
        .news{
            background-color: transparent;
        }
        .image{
            background-color: #F7F8FA !important;
            span{
                background-color: #F7F8FA !important;
            }
        }
        .news{
            background-image: none;
        }
        .frame_box{
            &:before, &:after{
                display: none;
            }
        }
        .work .bg:after{
            display: none;
        }
    }
}

*{
    &:focus{
        outline: 5px auto;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 1px !important;
        outline-color: coral !important;
    }
}

.page{
    overflow: hidden;
}

.mobile_show{
    display: none !important;
    @media only screen and (max-width: 991px) {
        display: block !important;
    }
}

.mobile_show2{
    display: none !important;
    @media only screen and (max-width: 767px) {
        display: block !important;
    }
}

.mobile_hide2{
    @media only screen and (max-width: 767px) {
        display: none !important;
    }
}

.mobile_hide{
    @media only screen and (max-width: 991px) {
        display: none !important;
    }
}

.rwd_search_box{
    width: 100%;
    background: $color3;
    padding: 15px;
    &.desctop_search_box{
        position: absolute;
        left: 0;
        width: 100%;
        top: 100%;
        z-index: 10;
        display: none;
        background: $color3;
        .rwd_search_box_holder{
            > div{
                &:first-child{
                    padding-right: 15px;
                    input{
                        padding: 0 15px;
                        background: $color_white;
                    }
                }
            }
        }
        @media only screen and (max-width: 991px) {
            background: $color3;
            display: block;
            position: relative;
            top: auto;
        }
    }
    .rwd_search_box_holder{
        > div{
            display: inline-block;
            vertical-align: top;
            input{
                width: 100%;
                height: 50px;
                border: 0;
                background-color: transparent;
                color: #56565A;
                font-size: 15px;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: #56565A;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: #56565A;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: #56565A;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: #56565A;
                }
                &[type="submit"]{
                    border-radius: 50%;
                    @include bg_pos;
                    background-color: $color_white;
                    font-size: 0;
                    background-image: url("./../img/mobile_search_icon.svg");
                }
            }
            &:first-child{
                width: calc(100% - 50px);
            }
            &:last-child{
                width: 50px;
            }
        }
    }
}

button{
    background-color: transparent;
    border: 0;
    padding: 0;
}

.elipse_btn{
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: $color3;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    @media only screen and (max-width: 1200px) {
        width: 45px;
        height: 45px;
        line-height: 45px;
    }
    &.rwd_btn{
        display: none !important;
        @media only screen and (max-width: 991px) {
            display: inline-block !important;
            span{
                display: block;
                width: 100%;
                width: 60%;
                height: 3px;
                background: $color1;
                margin: 0 auto 3px auto;
                line-height: unset;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}

.row{
    &.inline_row{
        &.inline_bottom{
            > div{
                vertical-align: bottom;
            }
        }
        > div{
            float: none;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.default_font{
    @media only screen and (max-width: 767px) {
        br{
            display: none;
        }
    }
    &.large{
        font-size: 17px;
        line-height: 23px;
        @media only screen and (max-width: 1200px) {
            font-size: 15px;
            line-height: 21px;
        }
        @media only screen and (max-width: 767px) {
            line-height: 18px;
        }
        li{
            color: $color_font1;
        }
        a{
            color: $color_font2;
            text-decoration: underline;
        }
        h1,h2,h3,h4,h5,h6,p,ul,ol, .lead_desc{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        @media only screen and (max-width: 767px) {
            p, .lead_desc{
                margin-bottom: 40px;
            }
        }
    }
}

.pd{
    padding: 0 7%;
    @media only screen and (max-width: 1200px) {
        padding: 0;
    }
}

.lead_desc{
    font-size: 24px;
    line-height: 30px;
    color: $color_font1;
    @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 26px;
    }
}

.page_header{
    border-top: 3px solid $color1;
    @media only screen and (max-width: 991px) {
        position: relative;
        z-index: 5;
    }
    .page_header_content{
        position: relative;
        @include content_width;
        @media only screen and (max-width: 991px) {
            display: block;

            > div{
                width: 100%;
            }
        }
    }
    .logo_box{
        .rwd_btn{
            margin-top: -30px;
            width: 30px;
            height: 21px;
            position: absolute;
            right: 0;
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            span{
                background: #56565A;
                display: block;
                height: 3px;
                position: absolute;
                right: 0;
                @include anim;
                &:nth-child(1){
                    width: 100%;
                    top: 0;
                }
                &:nth-child(2){
                    width: 25px;
                    top: 9px;
                }
                &:nth-child(3){
                    width: 20px;
                    top: 18px;
                }
            }
        }
        @media only screen and (max-width: 991px) {
            position: relative;
            padding: 29px 0;
        }
    }
    .navigation{
        @media only screen and (max-width: 991px) {
            filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
            position: absolute;
            top: 100%;
            background-color: $color_white;
            display: none;
            left: 0;
            width: 100%;
           > div{
               padding-left: 15px;
               padding-right: 15px;
           }
        }
        > div{
            &.menu_box{
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;
                @media only screen and (max-width: 1500px) {
                    margin-right: 15px;
                }
                @media only screen and (max-width: 991px) {
                    margin-right: 0;
                    padding-bottom: 40px;
                    width: 100%;
                }
                .menu{
                    > ul{
                        > li{
                            > ul{
                                .menu_inside {
                                    @media only screen and (min-width: 991px) {
                                        &:hover{
                                            &:after{
                                                right: 30px;
                                            }
                                        }
                                        &:after{
                                           right: 40px;
                                            transform: rotate(-90deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    @media only screen and (max-width: 991px) {
                        .menu_inside{
                            padding: 10px 0;
                        }
                    }
                    .menu_inside{
                        @media only screen and (min-width: 991px) {
                            &:after{
                                content: '';
                                width: 8px;
                                height: calc(100% - 5px);
                                position: absolute;
                                top: 0px;
                                right: 0;
                                background-size: 100% auto;
                                background-position: right center;
                                background-repeat: no-repeat;
                                background-image: url("./../img/date_toggle.svg");
                                @include anim;
                            }
                        }
                    }
                    div{
                        position: relative;
                        .rwd_mobile_btn{
                            z-index: 2;
                            background-color: $color3;
                            border-radius: 50%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 50px;
                            width: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url("./../img/rwd_mobile_arr1.svg");
                            &.active{
                                background-image: url("./../img/rwd_mobile_arr2.svg");
                            }
                        }
                    }
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                text-align: center;
                            }
                            @media only screen and (max-width: 991px) {
                                text-align: left;
                                border-bottom: 1px solid #EFF1F5;
                                > div{
                                    position: relative;
                                    //.rwd_mobile_btn{
                                    //    position: absolute;
                                    //    top: 0;
                                    //    right: 0;
                                    //    height: 100%;
                                    //    width: 50px;
                                    //    background-repeat: no-repeat;
                                    //    background-position: right center;
                                    //    background-image: url("./../img/rwd_mobile_arr1.svg");
                                    //    &.active{
                                    //        background-image: url("./../img/rwd_mobile_arr2.svg");
                                    //    }
                                    //}
                                }
                            }
                            &.menu_inside {
                                position: relative;
                                z-index: 5;
                                &:hover{
                                    &:after{
                                        transform: rotate(180deg);
                                    }
                                    > ul{
                                        //opacity: 1;
                                        //visibility: visible;
                                        display: block !important;
                                        top: 100%;
                                    }
                                }
                                    > ul {
                                        display: none;
                                        //opacity: 0;
                                        //visibility: hidden;
                                        @include anim;
                                        width: 296px;
                                        z-index: 2;
                                        &:before{
                                            content: '';
                                            height: 15px;
                                            width: 100%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            background: linear-gradient(#000 0%, rgba(0, 0, 0, 0) 100%);
                                            opacity: 0.05;
                                            @media only screen and (max-width: 991px) {
                                                display: none;
                                            }
                                        }
                                        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
                                        position: absolute;
                                        left: -40px;
                                        top: calc(100% - 30px);
                                        padding: 47.5px 40px;
                                        background-color: $color_white;
                                        @media only screen and (max-width: 1500px) {
                                            padding: 40px 20px;
                                            left: -20px;
                                        }
                                        @media only screen and (max-width: 991px) {
                                            position: relative;
                                            left: auto;
                                            top: auto;
                                            padding: 0;
                                            box-shadow: none;
                                            opacity: 1;
                                            visibility: visible;
                                            width: 100%;
                                            padding-left: 30px;
                                            display: none;
                                            transition: none;
                                        }
                                        > li{
                                            padding: 12.5px 0;
                                            &:hover{
                                                @media only screen and (min-width: 991px) {
                                                    ul{
                                                        left: 100%;
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }

                                            }
                                            > ul{
                                                opacity: 0;
                                                visibility: hidden;
                                                @include anim;
                                                width: 200px;
                                                box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
                                                position: absolute;
                                                left: calc(100% - 30px);
                                                top: 0;
                                                min-height: 100%;
                                                padding: 47.5px 40px;
                                                background-color: #EEEEEE;
                                                @media only screen and (max-width: 991px) {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    position: relative;
                                                    left: auto;
                                                    top: auto;
                                                    transition: none;
                                                    width: 100%;
                                                    display: none;
                                                    padding: 20px 0 0 30px;
                                                    box-shadow: none;
                                                    background: $color_white;
                                                }
                                                > li{
                                                    padding: 12.5px 0;
                                                    &:last-child{
                                                        margin-bottom: 0;
                                                    }
                                                }
                                            }
                                            @media only screen and (max-width: 991px) {
                                                margin-bottom: 20px;
                                            }
                                            &:last-child{
                                                margin-bottom: 0;
                                                @media only screen and (max-width: 991px) {
                                                    margin-bottom: 20px;
                                                }
                                            }
                                            a{
                                                font-size: 14px;
                                                line-height: 23px;
                                                color: $color_font2;
                                                font-weight: 600;
                                                @include anim;
                                                transform: translateX(0);
                                                display: inline-block;
                                                &:hover{
                                                    text-decoration: none;
                                                    color: $color1;
                                                    transform: translateX(10px);
                                                }
                                            }
                                        }
                                    }
                                }
                            > div{
                                padding: 0 20px;
                                position: relative;
                                @media only screen and (max-width: 1500px) {
                                    padding: 0 15px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    padding: 0 10px;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding-left: 0;
                                    padding-right: 0;
                                }
                                &:hover{
                                    @media only screen and (min-width: 992px) {
                                        &:after{
                                            width: 100%;
                                        }
                                    }
                                    a{
                                        text-decoration: none;
                                    }
                                }
                                &:after{
                                    border-bottom: 3px solid $color1;
                                    position: absolute;
                                    left: 0;
                                    width: 0;
                                    bottom: 0;
                                    @include anim;
                                    content: '';
                                }
                                > a{
                                    display: inline-block;
                                    padding: 40px 0;
                                    color: #18191A;
                                    font-size: 14px;
                                    line-height: 23px;
                                    letter-spacing: 0.03em;
                                    font-weight: 600;
                                    text-transform: uppercase;
                                    position: relative;
                                    @include anim;
                                    &.menu_open{
                                        color: $color1;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 12px;
                                        line-height: 21px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        font-size: 14px;
                                        line-height: 23px;
                                        padding: 15px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.page_header_btn_box{
                display: inline-block;
                vertical-align: middle;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                button, div{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.frame_box{
    position: relative;
    &:before, &:after{
        content: '';
        height: 100%;
        width: 50px;
        background: $color_white;
        position: absolute;
        top: 0;
        z-index: 2;
        @media only screen and (max-width: 1500px) {
            width: 30px;
        }
        @media only screen and (max-width: 1200px) {
            width: 15px;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    &:before{
        left: 0;
    }
    &:after{
        right: 0;
    }
}

.inner_frame_box{
    position: relative;
    background-color: $color_white;
    //min-height: 184px;
    padding-top: 80px;
    margin-top: -100px;
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-top: 40px;
      //  min-height: 120px;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0;
        .col-sm-5{
            padding-top: 30px;
        }
    }
    > div{
        position: relative;
        z-index: 3;
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
     &:after{
        content: '';
        background: $color_white;
        position: absolute;
        //top: -80px;
         top: 0;
        left: -80px;
        right: -80px;
        bottom: -80px;
       // z-index: 2;
         @media only screen and (max-width: 767px) {
             bottom: 0;
         }
    }
}

.carousel_nav{
    display: inline-block;
    vertical-align: top;
    padding-left: 30px;
    @media only screen and (max-width: 767px) {
        padding-left: 0;
    }
    .arr_btn{
        margin-right: 5px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.arr_btn{
    width: 55px;
    height: 55px;
    @include bg_pos;
    @include anim;
    background-color: rgba(0,0,0,0.3);
    position: relative;
    z-index: 2;
    &.arr_btn_light{
        background-color: $color_white;
        &:hover{
            background-color: #EEEEEE;
        }
        &.arr_btn_left{
            background-image: url("./../img/car_arr_left.svg");
        }
        &.arr_btn_right{
            background-image: url("./../img/car_arr_right.svg");
        }
    }
    &.arr_btn_left{
        background-image: url("./../img/sl_arr_left.svg");
    }
    &.arr_btn_right{
        background-image: url("./../img/sl_arr_right.svg");
    }
    &:hover{
        background-color: rgba(0,0,0,0.7);
    }
}

.scripts_btn, .car_scripts_btn{
    @media only screen and (max-width: 767px) {
        background-color:  rgba(0, 0, 0, 0.3);
    }
    &.dark{
        border-radius: 50%;
        background-color: $color1;
    }
    width: 55px;
    height: 55px;
    @include bg_pos;
    &.scripts_off_btn, &.carousel_pause_btn{
        background-image: url("./../img/pause.svg");
    }
    &.scripts_on_btn, &.carousel_play_btn{
        display: none;
        background-image: url("./../img/play2.svg");
    }
}

.slider_play_pause{
    @media only screen and (max-width: 767px) {
        display: inline-block;
        vertical-align: top;

    }
}

.slider{
    height: 640px;
    width: 100%;
    background: $color_black;
    position: relative;
    @media only screen and (max-width: 1500px) {
        height: 540px;
    }
    @media only screen and (max-width: 1200px) {
        height: 440px;
    }
    @media only screen and (max-width: 767px) {
        height: 467px;
        background:$color2;
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            .image{
                @include fill;
                @include bg_pos;
                background-size: cover;
                @media only screen and (max-width: 767px) {
                    height: 50%;
                }
                .cover{
                    @include fill;
                    opacity: 0.4;
                    background: $color_black;
                    @media only screen and (max-width: 767px) {
                        opacity: 0;
                    }
                }
            }
            .slider_content{
                position: relative;
                z-index: 2;
                padding-top: 174px;
                @media only screen and (max-width: 1500px) {
                    padding-top: 110px;
                }
                @media only screen and (max-width: 1200px) {
                    padding-top: 74px;
                }
                @media only screen and (max-width: 767px) {
                    padding: 30px 0;
                    height: 50%;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    .btn1{
                        display: none;
                    }
                }
                .default_font, .short_desc{
                    color: $color_white;
                }
                .title{
                    margin-bottom: 40px;
                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 20px;
                    }
                    h1{
                        color: $color_white;
                        @media only screen and (max-width: 767px) {
                            font-size: 28px;
                            line-height: 36px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .short_desc{
                    margin-bottom: 40px;
                    @media only screen and (max-width: 767px) {
                        .default_font{
                            &.large{
                                font-size: 13px;
                                p{
                                    display: -webkit-box;
                                    -webkit-line-clamp: 5;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .slider_nav{
        @include fill;
        @include vertical;
        @media only screen and (max-width: 767px) {
            display: block;
            top: calc(50% - 55px);
        }
        .nav_holder{
            .arr_btn{
                margin-left: auto;
                display: block;
                @media only screen and (max-width: 767px) {
                    display: inline-block;
                    vertical-align: top;
                    &:first-child{
                        background-color: rgba(0,0,0,0.3);
                    }
                    &:last-child{
                        background-color: rgba(0,0,0,0.7);
                    }
                }
            }
        }
    }
}

.page_footer{
    .footer_contact{
        @media only screen and (max-width: 991px) {
            text-align: center;
            .inline_row{
                > div{
                    width: 100%;
                    &:last-child{
                        padding-top: 30px;
                    }
                }
            }
        }
        @media only screen and (max-width: 767px) {
            .section_header{
                padding-left: 0;
                text-align: center;
                padding-top: 0;
                strong{
                    margin-left: 0;
                    &:before{
                        display: block;
                        margin: 0 auto 30px auto;
                    }
                }
            }
        }
        &.grey_color{
            background: $color3;
        }
    }
    .footer_contact_box{
        position: relative;
        padding: 27.5px 80px 47.5px 80px;
        background: $color2;
        color: $color_white;
        clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 50px, 100% 100%, 0 100%);
        z-index: 2;
        @media only screen and (max-width: 1500px) {
            padding-left: 50px;
            padding-right: 50px;
        }
        @media only screen and (max-width: 991px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        .section_header{
            margin-bottom: 0;
            padding-top: 25px;
        }
    }
}

.section_header{
    margin-bottom: 30px;
    padding-left: 30px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
    }
    span{
        color: $color_font2;
    }
    &.with_icon{
        padding-top: 100px;
        background-repeat: no-repeat;
        background-position: left top;
        @media only screen and (max-width: 767px) {
            padding-top: 80px;
        }
    }
    &.no_accent{
        padding-left: 0;
        strong{
            margin-left: 0;
            &:before{
                display: none;
            }
        }
    }
    .default_font{
        h1,h2,h3,h4,h5,h6{
            color: $color_font1;
        }
    }
    strong{
        font-weight: 600;
        position: relative;

        margin-left: -30px;
        &:before{
            margin-right: 20px;
            content: '';
            width: 10px;
            height: 10px;
            background: $color1;
            border-radius: 2px;
            transform: rotate(45deg);
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 7px;
            //@media only screen and (max-width: 767px) {
            //    margin-right: 13px;
            //}
        }
    }
    &.white{
        color: $color_white;
        .default_font{
            color: inherit;
            *{
                color: inherit;
            }
        }
    }
    .inline_row{
        > div{
            //@media only screen and (max-width: 767px) {
            //    &:first-child{
            //        width: calc(100% - 110px);
            //    }
            //    &:last-child{
            //        width: 110px;
            //    }
            //}
            h2{
                color: $color_font1;
                font-size: 30px;
                line-height: 35px;
                @media only screen and (max-width: 767px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }
    > p{
        font-size: 30px;
        line-height: 35px;
        @media only screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
        }

    }
}

.contact_map{
    position: relative;
    height: 390px;
    margin-top: -90px;
    @media only screen and (max-width: 991px) {
        margin-top: 30px;
    }
    @media only screen and (max-width: 767px) {
        height: 375px;
        //margin-top: -50px;
    }
    iframe{
        @include fill;
        border: 0;
    }
}

.contact_list1{
    padding-top: 20px;
    > ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            display: block;
        }
        > li{
            text-align: center;
            @media only screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            span{
                margin-top: 8px;
                font-size: 12px;
                line-height: 12px;
                color: #D4D7DD;
                display: inline-block;
                padding-right: 14px;
                background-repeat: no-repeat;
                background-position: right  top 2px;
                background-image: url("./../img/more_arr3.svg");

            }
            a, p{
                padding-top: 40px;
                background-repeat: no-repeat;
                background-position: center top;
                display: block;
                color: $color_white;
                font-weight: 600;
                margin-bottom: 0;

            }
            &.adress{
                p{
                    background-image: url("./../img/contact_icon1.svg");
                }
            }
            &.phone{
                a{
                    background-image: url("./../img/contact_icon2.svg");
                }
            }
            &.mail{
                a{
                    background-image: url("./../img/contact_icon3.svg");
                }
            }
        }
    }
}

.general_footer{
    border-top: 1px solid #EAEAEA;
    @media only screen and (max-width: 991px) {
        .page_map_btn{
            width: 100%;
        }
    }
    .shorts{
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        //    margin-top: -120px;
        }
    }
    a{
        @include anim;
        color: $color_font2;
        &:hover{
            color: $color1;
            text-decoration: none;
        }
    }
    .fh{
        font-size: 20px;
        line-height: 26px;
        font-weight: 600;
        color: $color1;
        margin-bottom: 30px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: 25px;
        }
        &.dark{
            color: $color_font1;
        }
        p{
            font-weight: inherit;
        }
    }
    .footer_nav{
        li{
            margin-bottom: 11px;
            a{
                font-weight: 600;
            }
        }
    }
    .site_map{
        display: none;
        background: $color3;
        margin-bottom: 30px;
        width: 100%;
        @media only screen and (max-width: 767px) {
            .col-xs-6{
                margin-bottom: 30px;
            }
        }
        .site_map_content{
            padding: 60px 0;
            @media only screen and (max-width: 1500px) {
                padding: 40px 0;
            }
            @media only screen and (max-width: 767px) {
                padding-bottom: 0;
            }

            > div > div {
                > ul {
                    margin: 0 -15px;
                    > li {
                        padding: 0 15px;
                        display: inline-block;
                        width: calc(100% / 6);
                        vertical-align: top;
                        @media only screen and (max-width: 767px) {
                            width: 50%;
                            margin-bottom: 50px;
                        }
                        li{
                            margin-bottom: 11px;
                            a{
                                font-size: 14px;
                                line-height: 21px;
                                color: #555658;
                                font-weight: 600;
                                @include anim;
                                &:hover{
                                    color: $color1;
                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        > div {
                            font-size: 20px;
                            line-height: 26px;
                            font-weight: 700;
                            color: $color_black;
                            margin-bottom: 30px;
                            font-weight: 700;
                            @media only screen and (max-width: 1500px) {
                                margin-bottom: 20px;
                            }
                            @media only screen and (max-width: 767px) {
                                margin-bottom: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.general_footer_top{
    padding: 84px 0 79px 0;
    @media only screen and (max-width: 1500px) {
        padding: 54px 0 49px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 0;
    }
    @include anim;
    &.site_map_visible{
        padding: 84px 0 30px 0;
    }
    .contact_list{
        margin-bottom: 53px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
    div.default_font{
        padding-bottom: 25px;
    }
}

.general_footer_bottom{
    padding: 53px 0 56px 0;
    border-top: 1px solid #EAEAEA;
    @media only screen and (max-width: 1500px) {
        padding: 40px 0;
    }
    @media only screen and (max-width: 767px) {
        text-align: center;
        .text-right{
            text-align: center;
            padding-top: 40px;
        }
    }
    .default_font{
        span{
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            &:first-child{
                margin-right: 58px;
                @media only screen and (max-width: 767px) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.contact_list{
    &.large{
        margin-bottom: 55px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
        > ul{
            > li{
                margin-bottom: 25px;
                padding-left: 33px;
                &:last-child{
                    margin-bottom: 0;
                }
                &.adress{
                    background-image: url("./../img/large_contact_icon1.svg");
                }
                &.phone{
                    background-image: url("./../img/large_contact_icon2.svg");
                }
                &.mail{
                    background-image: url("./../img/large_contact_icon3.svg");
                }
            }
        }
    }
    > ul{
        > li{
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 24px;
            margin-bottom: 18px;
            &:last-child{
                margin-bottom: 0 !important;
            }
            a{
                color: $color_font2;
            }
            &.adress{
                background-image: url("./../img/contact_icon4.svg");
            }
            &.phone{
                background-image: url("./../img/contact_icon5.svg");
            }
            &.mail{
                background-image: url("./../img/contact_icon6.svg");
            }
        }
    }
}

.page_map_btn{
    font-size: 14px;
    line-height: 55px;
    padding: 0px 40px 0px 40px;
    background-color: $color3;
    color: $color_black;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    @include anim;
    &.active{
        color: $color_white;
        background-color: $color1;
        span{
            background-image: url("./../img/toggle_arr2.svg");
        }
    }
    span{
        display: inline-block;
        padding-right: 32px;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("./../img/toggle_arr.svg");
    }
}

.element_list{
    &.carousel_content{
        overflow: hidden;
        margin-bottom: 32px;
        padding: 2px;
        > ul{
            position: relative;
            white-space: nowrap;
            > li{
                white-space: normal;
                margin-bottom: 0;
            }
        }
    }
    &.two_in_row{
        > ul{
            > li{
                width: 50%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &.three_in_row{
        > ul{
            > li{
                width: 33.33%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    &.six_in_row{
        > ul{
            > li{
                width: calc(100% / 6);
                @media only screen and (max-width: 991px) {
                    width: 25%;
                }
            }
        }
    }
    > ul{
        margin: 0 -16px;
        > li{
            display: inline-block;
            vertical-align: top;
            padding: 0 16px;
            margin-bottom: 32px;
            width: 25%;
            @media only screen and (max-width: 991px) {
                width: 33.33%;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            &.promoted_holder{
                width: 100%;
            }
            &:hover{
                a{
                    text-decoration: none;
                }
            }
        }
    }
}

.news{
    position: relative;
    padding: 80px 0;
    background-color: #F7F8FA;
    @include bg_pos;
    background-image: url("./../img/bg_lines.svg");
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
}

.desc_with_header{
    text-align: center;
    position: relative;
    z-index: 2;
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    &.desc_with_header2{
        text-align: left;
    }
    .short_desc{
        padding-top: 27px;
        @media only screen and (max-width: 767px) {
            padding-top: 24px;
        }
        &.white{
            color: $color_white;
        }
    }
}

.news_box{
    border: 1px solid $color3;
    &.with_short_desc{
        border: 1px solid $color3;
        @media only screen and (max-width: 767px) {
            border: 0;
            background: $color3;
            padding: 40px 30px;
            .image{
                width: 60px;
                height: 60px;
                border: 2px solid #555658;
                border-radius: 50%;
                margin: 0 auto 20px auto;
                padding-bottom: 0;
                > div{
                    display: none;
                }
            }
            .desc{
                padding: 0;
                background: transparent;
            }
        }
        h2{
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            margin-bottom: 30px;
        }
    }
    &.with_hover{
        border: 1px solid $color3;
        position: relative;
        z-index: 5;
        &.on{
            .image{
                transform: translateY(0);
                div{
                    background: $color1;
                }
            }
            .desc{
                background: $color1;
                h3{
                    color: $color_white;
                }
            }
        }
        &:hover{
            z-index: 10;
            @media only screen and (min-width: 767px) {
                .image{
                    transform: translateY(0);
                    div{
                        background: $color1;
                    }
                }
                .desc{
                    z-index: 2;
                    transform: translateY(0);
                    background: $color1;
                    .element_box_details{
                        opacity: 1;
                        visibility: visible;
                    }
                    h3{
                        color: $color_white;
                    }
                }
            }

        }
        > div{
            &.image{
                padding-bottom: 70.93%;
                div{
                    @include anim;
                }
            }
            &.desc{
                position: relative;
                z-index: 0;
                h3{
                    @include anim;
                }
                .element_box_details{
                    z-index: 3;
                    @include anim;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: $color_white;
                    padding: 40px;
                    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
                    @media only screen and (max-width: 767px) {
                        opacity: 1 !important;
                        visibility: visible !important;
                        display: none;
                        position: relative;
                        transition: none;
                    }
                    > span{
                        background: $color1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: auto;
                        bottom: auto;
                        transform: rotate(180deg);
                        display: block;
                        width: 41px;
                        height: 46px;
                        clip-path: polygon(0 0, 0% 100%, 100% 100%);
                    }
                    .element_box_details_row{
                        @include content_width;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: 1px solid $color3;
                        color: $color_font2;
                        &:last-child{
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    &.promoted_news_box{
        min-height: 455px;
        width: 100%;
        display: flex;
        @media only screen and (max-width: 767px) {
            min-height: 0;
            flex-flow: column;
        }
        > div{
            width: 50%;
            @media only screen and (max-width: 767px) {
                width: 100%;
                align-items: stretch;
            }
            &.image{
                padding-bottom: 0;
                height: auto;
                @media only screen and (max-width: 767px) {
                    order: 1;
                    height: 0;
                    padding-bottom: 73.43%;
                }
                > div{
                    top: 0;
                    right: -1px;
                    left: auto;
                    bottom: auto;
                    transform: rotate(180deg);
                }
            }
            &.desc{
                @include vertical;
                @media only screen and (max-width: 767px) {
                    order: 2;
                    display: block;
                }
                > div{
                    padding: 0 23px;
                    width: 100%;
                    @media only screen and (max-width: 767px) {
                        padding: 0;
                    }
                    //32px 30px 34px 30px
                }
            }
        }
    }
    @media only screen and (min-width: 767px) {
        &:hover{
            .image{
                transform: translateY(16px);
            }
            .desc{
                transform: translateY(-16px);
            }
        }
    }
    .image{
        @include anim;
        @include bg_pos;
        @include default_image;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-bottom: 73.43%;
        position: relative;
        transform: translateY(0);
        div{
            width: 30px;
            height: 34px;
            position: absolute;
            bottom: -1px;
            left: 0;
            background: $color_white;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
    }
    .desc{
        @include anim;
        background: $color_white;
        padding: 32px 30px 34px 30px;
        transform: translateY(0);
        position: relative;
        z-index: 2;
        @media only screen and (max-width: 767px) {
            padding: 30px;
        }
        h2{
            line-height: 36px;
            font-size: 30px;
            color: $color_font1;
            @media only screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        h3{
            color: $color_font1;
            font-size: 17px;
            line-height: 23px;
        }
        .short_desc{
            padding-top: 40px;
        }
    }
}

.date{
    font-size: 12px;
    color: #56565A;
    margin-bottom: 19px;
    font-weight: 600;
    text-transform: uppercase;
    &.white{
        padding-top: 10px;
        color: $color_white;
        strong{
            margin-left: 0;
            &:before{
                display: none;
            }
        }
    }
}

.rec_desc{
    margin-bottom: 50px;
}

.short_about{
    .about_box{
        min-height: 426px;
        display: flex;
        @media only screen and (max-width: 767px) {
            min-height: 0;
            flex-flow: column;
            margin-bottom: 40px;
        }
        > div{
            width: 50%;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            &.image{
                @include bg_pos;
                @include default_image;
                background-size: cover;
                clip-path: polygon(0 50px, 50px 0, 100% 0, 100% 100%, 0 100%);
                @media only screen and (max-width: 767px) {
                    order: 1;
                    height: 0;
                    padding-bottom: 84%;
                    //padding-bottom: 91.306%;
                    clip-path: polygon(0 30px, 30px 0, 100% 0, 100% 100%, 0 100%);
                }
            }
            &.desc{
                padding-right: 9%;
                padding-top: 67px;
                @media only screen and (max-width: 767px) {
                    order: 2;
                    padding-top: 39px;
                    padding-right: 0;
                }
                .default_font{
                    margin-bottom: 40px;
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

.banner{
    display: flex;
    position: relative;
    z-index: 2;
    min-height: 200px;
    @media only screen and (max-width: 767px) {
        min-height: 0;
        display: block;
    }
    > a{
        display: inline-block;
        vertical-align: top;
        width: 50%;
        padding: 0 80px;
        @include vertical;
        background-repeat: no-repeat;
        background-position: right 80px center;
        > div{
            display: inline-block;
            vertical-align: middle;
            &.icon{
                width: 60px;
                height: 60px;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 20px;
            }
        }
        @media only screen and (max-width: 767px) {
            background-position: right 30px center;
        }
        @include anim;
        @media only screen and (max-width: 1200px) {
            padding: 0 50px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 30px;
        }
        &:hover{
            text-decoration: none;
            background-position: right 50px center;
        }
        .default_font{
            @media only screen and (max-width: 767px) {
                padding-left: 0;
                padding-top: 85px;
            }
        }
        &:first-child{
            background-color: $color1;
            background-image: url("./../img/banner_arr_icon1.svg");
            .default_font{
                color: $color_white;
                h4{
                    color: inherit;
                }
            }
        }
        &:last-child{
            background-color: $color_white;
            background-image: url("./../img/banner_arr_icon2.svg");
        }
        > div{
            width: 100%;
        }
    }
}

.why{
    margin-top: -74px;
    padding-top: 194px;
    background-color: $color2;
    padding-bottom: 90px;
    @include bg_pos;
    background-image: url("./../img/bg_line2.svg");
    @media only screen and (max-width: 1500px) {
        padding-top: 130px;
        padding-bottom: 50px;
    }
    .element_list{
        padding-top: 10px;
        text-align: center;
    }
    @media only screen and (max-width: 767px) {
        .section_header{
            text-align: left;
            .row{
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    float: none;
                }
            }
            .default_font{
                white-space: nowrap;
            }
        }
        .element_list{
            &.six_in_row{
                overflow: hidden;
                > ul{
                    white-space: nowrap;
                    > li{
                        width: 50%;
                        white-space: normal;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .car_dots{
        text-align: center;
       // margin-bottom: -20px;
        padding-top: 50px;
        &.dark{
            margin-top: 50px;
            margin-bottom: 0;
            button{
                background: $color1 !important;
                opacity: 0.5;
                &.on{
                    opacity: 1;
                }
            }
        }
        button{
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgba(255,255,255,0.5);
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
            &.on{
                background: rgba(255,255,255,1);
            }
        }
    }
}

.icon_box{
    &.large{
        .icon{
            width: 100px;
            height: 100px;
            @media only screen and (max-width: 1500px) {
                width: 80px;
                height: 80px;
            }
        }
    }
    .icon{
        width: 60px;
        height: 60px;
        @include bg_pos;
        border-radius: 50%;
        background-color: $color1;
        margin: 0 auto 30px auto;
        @media only screen and (max-width: 1500px) {
            background-size: 30%;
        }
    }
    .desc{
        color: $color_white;
        text-align: center;
        @media only screen and (max-width: 767px) {
            padding: 0 15px;
            br{
                display: none;
            }
        }
    }
}

.numbers{
    padding: 80px 0 20px 0;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
    }
    &.numbers2{
        background: $color3;
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
    }
    .element_list{
        padding-top: 10px;
        text-align: center;
        > ul{
            > li{
                margin-bottom: 60px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 767px) {
                    width: 50%;
                }
            }
        }
    }
}

.number_box{
    text-align: center;
    .icon{
        width: 60px;
        height: 60px;
        margin: 0 auto 30px auto;
        @include bg_pos;
    }
    .desc{
        .default_font{
            .num_title{
                font-size: 38px;
                line-height: 46px;
                color: $color_font1;
                @media only screen and (max-width: 1200px) {
                    font-size: 30px;
                    line-height: 38px;
                }
                margin-bottom: 0;
                @media only screen and (max-width: 767px) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
            //h2{
            //    margin-bottom: 0;
            //    @media only screen and (max-width: 767px) {
            //        font-size: 24px;
            //        line-height: 30px;
            //    }
            //}
        }
    }
}

.specialty{
    min-height: 742px;
    background: $color_black;
    padding: 112px 0;
    .element_list{
        text-align: center;
    }
    @media only screen and (max-width: 1500px) {
        padding: 60px 0;
        min-height: 600px;
    }
    @media only screen and (max-width: 767px) {
        padding: 44px 0 40px 0;
    }
    &.step_banner{
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
    }
    .container{
        position: relative;
        z-index: 2;
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        opacity: 0.5;
    }
    .more{
        padding-top: 50px;
        @media only screen and (max-width: 1500px) {
            padding-top: 30px;
        }
        @media only screen and (max-width: 767px) {
            padding-top: 10px;
        }
    }
    .element_list{
        > ul{
            > li{
                @media only screen and (max-width: 767px) {
                    width: 50%;
                }
            }
        }
    }
}

.work{
    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 0;
    }
    .work_box{
        padding: 83px 0 64px 0;
        @media only screen and (max-width: 1500px) {
            padding: 50px 0 30px 0;
        }
        @media only screen and (max-width: 767px) {
           padding: 40px 0;
        }
        .desc{
            .default_font{
                margin-bottom: 40px;
            }
        }
    }
    .container{
        position: relative;
        z-index: 2;
        .row{
            margin: 0 -115px;
            @media only screen and (max-width: 1500px) {
                margin: 0 -60px;
            }
            @media only screen and (max-width: 991px) {
                margin: 0 -30px;
            }
            > div{
                padding: 0 115px;
                @media only screen and (max-width: 1500px) {
                    padding: 0 60px;
                }
                @media only screen and (max-width: 991px) {
                    padding: 0 30px;
                }
                @media only screen and (max-width: 991px) {
                    &:last-child{
                        .work_box{
                            background: $color3;
                            margin-left: -15px;
                            width: calc(100% + 30px);
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
    .bg{
        position: relative;
        &:after{
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background: $color3;
            content: '';
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}

.shopping_banner{
    background-color: $color2;
    padding: 53px 0;
    @include bg_pos;
    background-image: url("./../img/bg_line3.svg");
    .default_font{
        color: $color_white;
        @media only screen and (max-width: 991px) {
            margin-bottom: 40px;
        }
        *{
            color: inherit;
        }
    }
}

.partners{
    padding: 80px 0 100px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 70px 0;
        .section_header{
            margin-bottom: 20px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 0;
        .section_header{
            margin-bottom: 50px;
        }
        background: linear-gradient(#f7f8fa 0%, #fff 100%);
        .arr_btn{
            border: 1px solid $color3;
        }
    }
    .partners_box{
        margin-top: 30px;
        &.partners_carousel{
            overflow: hidden;
            > ul{
                position: relative;
                //margin: 0 -15px;
                white-space: nowrap;
                > li{
                    width: calc(100% / 6);
                    padding: 0 15px;
                    text-align: center;
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 767px) {
                        width: 50%;
                    }
                    a{
                        width: 100%;
                        //height: 10px;
                        //background: red;
                        display: inline-block;
                       @include anim;
                    }
                }
            }
        }
        //@include content_width;
        //margin: 0 -15px;
        //@media only screen and (max-width: 767px) {
        //    display: block;
        //    white-space: nowrap;
        //    //overflow: hidden;
        //}
        a{
            //display: inline-block;
            //padding: 0 15px;
            //width: calc(100% / 6);
            //@include anim;
            //@media only screen and (max-width: 767px) {
            //    width: 50%;
            //    text-align: center;
            //}
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .mobile_show2{
        .row{
            > div{
                display: inline-block;
                vertical-align: middle;
                float:none;
            }
        }
    }
}

section.top{
    background-color: $color3;
    h2{
        font-size: 48px;
        line-height: 55px;
    }
    @media only screen and (max-width: 767px) {
        min-height: 235px;
        .section_header{
            margin-bottom: 30px !important;
        }
        .default_font{
            h2{
                font-size: 34px;
                line-height: 36px;
            }
        }
    }
    &.contact_top{
        height: 400px;
        @media only screen and (max-width: 1500px) {
            height: 300px;
        }
        @media only screen and (max-width: 767px) {
            height: auto;
        }
        .section_header{
            margin-bottom: 0;
        }
        .top_image{
            height: 100%;
        }
    }
    &.with_image{
        position: relative;
        background-color: transparent;
        .breadcrumbs{
            position: relative;
            z-index: 2;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            > ul{
                > li{
                    a{
                        color: $color_white;
                        &:after{
                            content: url("./../img/breadcrumbs_arr2.svg");
                        }
                    }
                }
            }
        }
    }
    .breadcrumbs{
        padding: 30px 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        @media only screen and (max-width: 767px) {
            padding: 20px 0;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                &:last-child{
                    a{
                        &:after{
                            display: none;
                        }
                    }
                }
                a{
                    font-size: 12px;
                    line-height: 25px;
                    font-weight: 600;
                    color: $color_font2;
                    text-transform: uppercase;
                    display: inline-block;
                    @media only screen and (max-width: 767px) {
                        line-height: 23px;
                    }
                    &:after{
                        content: url("./../img/breadcrumbs_arr.svg");
                        margin: 0 20px;
                    }
                }
            }
        }
    }
    .section_header{
        padding-top: 158px;
        position: relative;
        z-index: 2;
        margin-bottom: 160px;
        @media only screen and (max-width: 1500px) {
            padding-top: 100px;
        }
        @media only screen and (max-width: 767px) {
            margin-bottom: 0;
            padding-top: 75px;
        }
        &.details_title{
            padding-top: 76px;
        }
    }
    .top_image{
        background: $color_black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 513px;
        overflow: hidden;
        @media only screen and (max-width: 1500px) {
            height: 413px;
        }
        @media only screen and (max-width: 767px) {
            @include fill;
        }
        .image{
            @include bg_pos;
            @include fill;
            //height: 150%;
            opacity: 0.5;
            background-size: cover;
        }
    }
    .adress{
        margin-top: 10px;
    }
    .recruitment_contact {
        .lead_desc {
            span {
                a{
                    &:hover{
                        text-decoration: underline;
                        color: $color_white;
                    }
                }
            }
        }
    }
}

main.page_content{
    min-height: 520px;
    &.no_min_height{
        min-height: 0;
    }
}

.search_results{
    padding-top: 30px;
    background: $color3;
    @media only screen and (max-width: 767px) {
        margin-top: -120px;
        .container{
            > .row{
                &:first-child{
                    .section_header{
                        text-align: center;
                    }
                }
            }
        }
        .text-right{
            text-align: center;
        }
        .section_header{
            padding-left: 0;
            h1{
                strong{
                    margin-left: 0;
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
    .search_rows{
        padding-top: 30px;
        @media only screen and (max-width: 991px) {
            .carousel_nav{
                padding-left: 0;
            }
        }
        @media only screen and (max-width: 767px) {
            .section_header{
                .inline_row{
                    > div{
                        width: 100%;
                        &:last-child{
                            margin-top: 20px;
                            @include content_width;
                        }
                    }
                }
            }
        }
        .search_result_row{
            margin-bottom: 60px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 30px;
            }
        }
    }
    .result{
        font-size: 48px;
        line-height: 50px;
        color: $color1;
        @media only screen and (max-width: 767px) {
            font-size: 34px;
        }
        p{
            font-weight: 600;
        }
    }
}

.service_image_desc{
    @media only screen and (max-width: 767px) {
        display: flex;
        flex-flow: column;
        > div{
            align-items: stretch;
            &:first-child{
                order: 2;
            }
            &:last-child{
                order: 1;
            }
        }
    }
}

.tile_box{
    min-height: 200px;
    background-color: $color_white;
    background-image: url("./../img/banner_arr_icon2.svg");
    background-repeat: no-repeat;
    background-position: right 80px center;
    font-size: 24px;
    line-height: 30px;
    color: $color_font1;
    @include vertical;
    @include anim;
    > div{
        width: 100%;
    }
    @media only screen and (max-width: 991px) {
        background-position: right 30px center;
        min-height: 170px;
        font-size: 20px;
        line-height: 26px;
    }
    &.tile_with_icon{
        background-color: $color3;
        &.tile_box2{
            background-color: $color_white;
        }
        > div{
           > div{
               display: inline-block;
               vertical-align: middle;
               &:last-child{
                   padding-left: 20px;
                   width: calc(100% - 60px);
               }
               &.icon{
                   width: 60px;
                   height: 60px;
                   background-repeat: no-repeat;
                   background-position: center;
               }
               p{
                   //padding-left: 90px;

                   @media only screen and (max-width: 767px) {
                       background-image: none !important;
                       padding-left: 0;
                   }
               }
           }
        }
    }
    &:hover{
        background-image: url("./../img/banner_arr_icon2.svg");
        background-position: right 50px center;
    }
    > div{
        width: 100%;
        padding: 0 80px;
        @media only screen and (max-width: 991px) {
            padding: 0 30px;
        }
    }
}

.about{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
}

.history{
    .inner_frame_box{
        margin-bottom: 60px;
    }
    .frame_box{
        &:before, &:after{
            display: none !important;
        }
    }
}

.story_box_row{
    .dots{
        position: absolute;
        top: 0;
        height: 100%;
        @include vertical;
        right: 0;
        @media only screen and (max-width: 767px) {
            display: none;
        }
        > div{
            width: 10px;
            button{
                width: 10px;
                height: 10px;
                border: 2px solid $color_white;
                margin-bottom: 20px;
                display: block;
                right: -80px;
                position: relative;
                border-radius: 50%;
                &.on{
                    background: $color_white;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &:nth-child(odd){
        .story_box{
            > div{
                &.desc_holder{
                    left: 0;
                }
                &.image_holder{
                    left: 28%;
                    @media only screen and (max-width: 767px) {
                        left: 0;
                    }
                }
            }
        }
    }
    &:nth-child(even){
        .dots{
            > div{
                button{
                    border-color: $border-color !important;
                    margin-right: -10px;
                    &.on{
                        background: $border-color !important;
                    }
                }
            }
        }
        .story_box{
            > div{
                &.desc_holder{
                    left: 72%;
                    @media only screen and (max-width: 767px) {
                        left: 0;
                    }
                }
                &.image_holder{
                    left: 0;
                }
            }
        }
    }
}
.story_boxes{
    position: relative;
    > .dots{
        display: none;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        right: 0;

        z-index: 5;
        &.dots_dark{
            > div{
                button{
                    border-color: $border-color !important;
                    &.step_active{
                        background: $border_color !important;
                    }
                }
            }
        }
        > div{
            @include fill;
            @include vertical;
            button{
                @include anim;
                width: 10px;
                height: 10px;
                border: 2px solid $color_white;
                margin-bottom: 20px;
                display: block;
                border-radius: 50%;
                margin-left: auto;
                &.step_active{
                    background: $color_white;
                }
                &.on{
                    background: $color_white;
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.story_box{
    height: 100vh !important;
    position: relative;
    margin-bottom: 80px;
    @media only screen and (max-width: 767px) {
        height: auto !important;
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
    }
    > div{
        top: 0;
        height: 100%;
        position: absolute;
        @media only screen and (max-width: 767px) {
            position: relative;
            align-items: stretch;
        }
        &.desc_holder{
            width: 28%;
            @include vertical;
            @media only screen and (max-width: 767px) {
                width: 100%;
                display: block;
                order: 2;
            }
            > div{
                width: 100%;
                @media only screen and (max-width: 767px) {
                    margin-top: -60px;
                }
                .desc{
                    width: calc(100% + 154px);
                    background: $color_white;
                    position: relative;
                    z-index: 2;
                    .default_font{
                        h1,h2,h3,h4,h5,h6{
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                    @media only screen and (max-width: 767px) {
                        width: calc(100% - 30px);
                        padding: 40px 0px !important;
                        > div{
                            padding-right: 30px;
                        }
                    }
                    &.desc_left{
                        padding: 80px 50px 80px 0;
                    }
                    &.desc_right{
                        padding: 80px 50px 80px 50px;
                        margin-left: -154px;
                        @media only screen and (max-width: 767px) {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        &.image_holder{
            width: 72%;
            @media only screen and (max-width: 767px) {
                width: 100%;
                order: 1;
            }
            .image{
                @include fill;
                @include bg_pos;
                @include default_image;
                background-size: cover;
                @media only screen and (max-width: 767px) {
                    position: relative;
                    height: 0;
                    padding-bottom: 80%;
                }
            }
        }
    }
}

//.story_box_row{
//    .dots{
//        position: absolute;
//        top: 0;
//        height: 100%;
//        @include vertical;
//        right: 0;
//        > div{
//            width: 10px;
//            button{
//                width: 10px;
//                height: 10px;
//                border: 2px solid $color_white;
//                margin-bottom: 20px;
//                display: block;
//                right: -80px;
//                position: relative;
//                border-radius: 50%;
//                &.on{
//                    background: $color_white;
//                }
//                &:last-child{
//                    margin-bottom: 0;
//                }
//            }
//        }
//    }
//    &:nth-child(odd){
//        .story_slider{
//            li{
//                > div{
//                    &.desc_holder{
//                        left: 0;
//                    }
//                    &.image_holder{
//                        left: 28%;
//                    }
//                }
//            }
//        }
//    }
//    &:nth-child(even){
//        .dots{
//            > div{
//                button{
//                    border-color: $border-color !important;
//                    margin-right: -10px;
//                    &.on{
//                        background: $border-color !important;
//                    }
//                }
//            }
//        }
//        .story_slider{
//            li{
//                > div{
//                    &.desc_holder{
//                        left: 72%;
//                    }
//                    &.image_holder{
//                        left: 0;
//                    }
//                }
//            }
//        }
//    }
//}
//.story_boxes{
//    > div{
//        margin-bottom: 80px;
//    }
//}
//.story_slider{
//    height: 100vh;
//    width: 100%;
//    position: relative;
//    margin-top: 80px;
//    > ul{
//        @include fill;
//        > li{
//            @include fill;
//            > div{
//                position: absolute;
//                height: 100%;
//                top: 0;
//                &.desc_holder{
//                    width: 28%;
//                    @include vertical;
//                    > div{
//                        width: 100%;
//                        .desc{
//                            width: calc(100% + 154px);
//                            background: $color_white;
//                            position: relative;
//                            z-index: 2;
//                            &.desc_left{
//                                padding: 80px 50px 80px 0;
//                            }
//                            &.desc_right{
//                                padding: 80px 0 80px 50px;
//                                margin-left: -154px;
//                            }
//                        }
//                    }
//                }
//                &.image_holder{
//                    width: 72%;
//                    .image{
//                        @include fill;
//                        @include bg_pos;
//                        @include default_image;
//                        background-size: cover;
//                    }
//                }
//            }
//        }
//    }
//}

.blue_date{
    color: $color1;
    margin-bottom: 30px;
    padding-left: 25px;
    position: relative;
    &:before{
        content: '';
        width: 15px;
        height: 3px;
        background: $color1;
        position: absolute;
        left: 0;
        top: 40%;
    }
    strong{
        font-weight: 700;
    }
}

.management{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    .inner_frame_box{
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        .section_header{
            margin-bottom: 40px;
        }
    }
}

.management_list{
    li{
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.management_box{
    > div{
        display: inline-block;
        vertical-align: top;
        &.image{
            @include bg_pos;
            @include default_image;
            background-size: cover;
            width: 180px;
            height: 227px;
            @media only screen and (max-width: 767px) {
                display: block;
                margin: 0 auto 30px auto;
            }
        }
        &.desc{
            width: calc(100% - 180px);
            padding-left: 40px;
            padding-top: 20px;
            @media only screen and (max-width: 767px) {
                padding-top: 0;
                padding-left: 0;
                width: 100%;
            }
            .title{
                margin-bottom: 20px;
                .default_font{
                    h4{
                        margin-bottom: 0;
                    }
                }
            }
            .default_font{
                p{
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.see_more{
    background: $color3;
    padding: 80px 0;
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
        padding-top: 50px;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        padding-top: 40px;
    }
    &.more_tiles{
        @media only screen and (max-width: 1500px) {
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 767px) {
            padding-bottom: 40px;
        }
        .element_list{
            .tile_box{
                background-color: $color_white;
            }
        }
    }
    .section_header{
        margin-bottom: 40px;
    }
}

.years{
    margin-bottom: 20px !important;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }
    > ul{
        white-space: nowrap;
        > li{
            margin-bottom: 10px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.year_btn{
    display: inline-block;
    font-size: 14px;
    line-height: 45px;
    font-weight: 600;
    color: $color_font2;
    background: $color3;
    padding: 0 20px;
    &.active{
        background: #0E2033;
        color: $color_white;
    }
}

.main_news{
    .element_list{
        position: relative;
        z-index: 2;
        margin-top: -60px;

        @media only screen and (max-width: 1500px) {
            margin-top: 0;
        }
    }
}

.pagi_box{
    margin-bottom: 75px;
    text-align: center;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
    .myajaxlist_paginate_box{
        background: $color_white;
        border: 0;
        border-radius: 0;
        padding: 0;
        margin-top: 30px;
        @media only screen and (max-width: 1500px) {
            margin-top: 20px;
        }
        @media only screen and (max-width: 767px) {
           // padding: 13px 15px;
            margin-top: 0;
        }
        > div{
            vertical-align: middle;
        }
        >div.arrow_box{
            width: auto;;
            background-color: #0E2033;
            color: $color_white;
            @media only screen and (max-width: 767px) {
                //width: 100%;
                text-align: center !important;
            }
            >div{
                font-size: 14px;
                text-transform: none;
                padding-top: 0;
                height: auto;
                position: relative;
                line-height: 42px;
                padding-top: 3px;
                text-transform: uppercase;
                background-repeat: no-repeat;
                @media only screen and (max-width: 767px) {
                    display: inline-block;
                }
                &:hover{
                    color: $color_white;
                }
                &:after{
                    display: none;
                }
                &.left_button{
                    padding-right: 30px;
                    padding-left: 57px;
                    border: 0;
                    background-position: left 29px center;
                    background-image: url("./../img/pagi_arr_left.svg");
                    @media only screen and (max-width: 767px) {
                        border-right: 0;
                    }
                }
                &.right_button{
                    padding-left: 30px;
                    padding-right: 57px;
                    border: 0;
                    background-position: right 29px center;
                    background-image: url("./../img/pagi_arr_right.svg");
                    @media only screen and (max-width: 767px) {
                        border-left: 0;
                    }
                }
            }
        }
        > div.pages_box{
            width: auto;
            margin: 0 40px;
            @media only screen and (max-width: 991px) {
                margin: 0 20px;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin: 30px 0;
                padding: 0;
            }
            > div{
                width: 45px;
                height: 45px;
                line-height: 45px;
                padding-top: 0;
                font-size: 14px;
                font-weight: 700;
                background: $color3;
                color: $color_font2;
                border-radius: 0;
                margin-right: 5px;
                &:last-child{
                    margin-right: 0;
                }
                &.dots{
                    background: transparent;
                    @media only screen and (max-width: 767px) {
                        display: none !important;
                    }
                }
                &.active, &:hover{
                    background: #0E2033 !important;
                    color: $color_white;
                }
            }
        }
    }
}

.news_details{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
        .element_list{
            > ul{
                > li{
                    width: 50%;
                }
            }
        }
    }
    .main_text_content{
        padding-bottom: 20px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 0;
        }
    }
    .article_part{
        > .row{
            margin-bottom: 60px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 40px;
            }
            &:first-child{
                margin-bottom: 0;
            }
        }
    }
    .image{
        &.lead_image{
            display: block;
            padding-bottom: 0;
            //height: 700px;
            height: 0;
            padding-bottom: 56.452%;
            width: 100%;
            @include bg_pos;
            @include default_image;
            background-size: cover;
            @media only screen and (max-width: 991px) {
                margin-top: 40px;
            }
        }
    }
}

.separate{
    padding-top: 60px;
    border-top: 1px solid #EFF1F5;
    padding-bottom: 30px;
    @media only screen and (max-width: 1500px) {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    &.separate2{
        padding-bottom: 0;
    }
}


.image{
    &.gallery_image{
        width: 100%;
        height: 0;
        padding-bottom: 73.43%;

        @include anim;
        position: relative;
        span{
            @include fill;
            display: block;
            @include bg_pos;
            @include default_image;
            background-size: cover;
        }
        .cover{
            @include fill;
            @include vertical;
            cursor: pointer;
            z-index: 2;
            > div{
                text-align: center;
                width: 100%;
            }
        }
        .movie{
            @include fill;
        }
        &.grid_element{
            @include fill;
            padding-bottom: 0;
        }
        &:hover{
            transform: scale(0.97);
        }
    }
}

.download_box{
    padding: 28px 90px 28px 30px;
    background-color: $color3;
    background-repeat: no-repeat;
    background-position: right 28px center;
    background-image: url("./../img/d_icon2.svg");
    @include anim;
    &:hover{
        transform: scale(0.97);
    }
    > div{
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("./../img/d_icon1.svg");
        padding-left: 33px;
    }
}
.gallery{
    margin-bottom: 30px;
    .movie_box{
        iframe{
            @include fill;
            border: 0;
        }
    }
    &.separate{
        margin-bottom: 0;
    }
}
.gallery_grid_box{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-gap: 32px;
    grid-auto-rows: minmax(210px, auto);
    grid-auto-flow: dense;
    margin-top: 60px;
    margin-bottom: 90px;
    a{
        display: block;
        @include fill;

    }
    @media only screen and (max-width: 1500px) {
        grid-auto-rows: minmax(192px, auto);
        margin-top: 40px;
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 1200px) {
        grid-auto-rows: minmax(162px, auto);
    }
    @media only screen and (max-width: 991px) {
        grid-auto-rows: minmax(120px, auto);
        grid-gap: 15px;
    }
    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-auto-rows: minmax(56vw, auto);
    }
    > div{
        position: relative;
        &.large{
            grid-column-end: span 3;
            grid-row-end: span 2;
            @media only screen and (max-width: 767px) {
                grid-column-end: span 1;
                grid-row-end: span 1;
            }
        }
    }
}

.text_page{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
}

.offer, .company{
    .element_list{
      //  padding-top: 60px;
        padding-bottom: 50px;
        @media only screen and (max-width: 1500px) {
           // padding-top: 40px;
        }
        @media only screen and (max-width: 991px) {
            padding-bottom: 30px;
            padding-top: 40px;
        }
    }
}

.offer_form_box{
    border: 1px solid #eff1f5;
    padding: 80px 105px;
    position: relative;
    z-index: 2;
    background-color: $color_white;
    filter: drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.05));
    @media only screen and (max-width: 767px) {
        padding: 30px 30px 40px 30px;
        margin-left: -15px;
        width: calc(100%  + 30px);
    }
    @media only screen and (max-width: 767px) {
        .col-sm-6{
            margin-bottom: 32px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .row{
        margin-bottom: 32px;
        &:first-child, &:last-child{
            margin-bottom: 0;
        }
    }
}
.station{
    > div{
        display: inline-block;
        vertical-align: bottom;
        &.input_holder{
            width: calc((100% - 120px) / 2);
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
        &.icon_holder{
            width: 120px;
            @media only screen and (max-width: 767px) {
                width: 100%;
                padding: 30px 0;
            }
            .icon{
                width: 55px;
                height: 55px;
                border-radius: 50%;
                @include bg_pos;
                background-image: url("./../img/station_arr.svg");
                border: 2px solid #EFF1F5;
                margin: 0 auto;
                @media only screen and (max-width: 767px) {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
.default_form{
    &.default_form2{
        .select2-container--default {
            text-align: left;
            .select2-selection--single {
                border: 1px solid #eff1f5;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
                background: $color_white;
                border-radius: 0;
            }
        }
    }
    select{
        width: 100%;
    }
    .select2-container--default {
        text-align: left;
        .select2-selection--single {
            border: 1px solid #D4D7DD;
            height: 55px;
            border-radius: 0;
            background: $color3;
            color: $color_font2;
            font-size: 14px;
            font-weight: 400;
            border-radius: 5px;
            .select2-selection__rendered{
                line-height: 55px;
                padding: 0 20px;
                padding-right: 90px;
            }
            .select2-selection__arrow{
                width: 80px;
                height: 100%;
                top: 0;
                right: 0;
                background-repeat: no-repeat;
                background-image: url("./../img/date_toggle.svg");
                background-position: right 20px center;
                b{
                    display: none;
                }
            }
        }
        .select2-search--dropdown {
            .select2-search__field{
                background: $color3;
                color: $color_font1;
                border: none;
                border-bottom: 2px solid #e0e5e8;
                height: 36px;
            }
        }
    }

    .select2-results__option{
        &.select2-results__option--highlighted{
            background-color: $color2 !important;
        }
    }

    .select2-dropdown{
        border: 1px solid #e0e5e8;
        border-top: none;
    }

    .select2-container--default .select2-results__option[aria-selected=true]{
        background-color: #F8F8F8;
    }

    .select2-results{
        padding: 0 4px;
    }

    .quantity {
        position: relative;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]
    {
        -moz-appearance: textfield;
    }

    .quantity input {
        width: 45px;
        height: 42px;
        line-height: 1.65;
        float: left;
        display: block;
        padding: 0;
        margin: 0;
        padding-left: 20px;
        border: 1px solid #eee;
    }

    .quantity input:focus {
        outline: 0;
    }

    .quantity-nav {
        position: absolute;
        top: 13px;
        right: 20px;
        border: 1px solid #D4D7DD;
        width: 18px;
        height: 30px;
    }

    .quantity-button {
        display: block;
        width: 100%;
        height: 50%;
        background-color: $color_white;
        @include bg_pos;
        cursor: pointer;
    }

    .quantity-button.quantity-up {
        border-bottom: 1px solid #D4D7DD;
        background-image: url("./../img/num_arr1.svg");
    }

    .quantity-button.quantity-down {
        background-image: url("./../img/num_arr2.svg");
    }

    .number_input{

        position: relative;
        .number_nav{
            position: absolute;
            top: 13px;
            right: 20px;
            border: 1px solid #D4D7DD;
            width: 18px;
            height: 30px;
            .value_btn{
                display: block;
                width: 100%;
                height: 50%;
                background-color: $color_white;
                @include bg_pos;
                &.value_up{
                    border-bottom: 1px solid #D4D7DD;
                    background-image: url("./../img/num_arr1.svg");
                }
                &.value_down{
                    background-image: url("./../img/num_arr2.svg");
                }
            }
        }
    }
    .next_div{
        width: 246px;
        display: inline-block;
        vertical-align: bottom;
        margin-right: 10px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
        &:last-child{
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    .date_label{
        width: 30px;
    }
    input[type="date"]{
        position: relative;
        padding: 0 15px;
        height: 55px;
        border: 1px solid #D4D7DD;
        background-color: $color3;
        font-size: 14px;
        color: $color_font1;
        width: calc(100% - 30px);
        border-radius: 5px;
        padding-left: 50px;
        background-repeat: no-repeat;
       // background-image: url("./../img/date_toggle.svg");
        background-position: right 20px center;
        &::-webkit-calendar-picker-indicator {
            background-image: url("./../img/date_icon.svg");
            background-position: left center;
            position: absolute;
           // left: 40px;
            left: 15px;
            cursor: pointer;
        }
    }

    label, .default_font{
        text-align: left;
        color: $color_black;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 13px;
        span{
            font-size: 12px;
            color: $color_font2;
        }
    }
    .field_radio{
        .field_radio_box{
            display: inline-block;
            vertical-align: top;
            margin-right: 19px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
.date_holder{
    display: inline-block;
    vertical-align: top;
    width: 278px;
    margin-right: 30px;
    @media only screen and (max-width: 991px) {
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;
    }
    &:last-child{
        margin-bottom: 0;
    }
}
.captcha{
    margin-bottom: 30px;
}
.rules{
    > div{
        display: inline-block;
        vertical-align: top;
        &.default_form{
            width: 15px;
        }
        &.default_font{
            padding-left: 15px;
            width: calc(100% - 15px);
            color: $color_black;
            a{
                color: $color1;
                text-decoration: underline;
            }
        }
    }
}

.actions{
    background: $color3;
    padding: 85px 0 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
}
.map_section{
    padding: 120px 0;
    background-color: $color2;
    margin-bottom: 80px;
    @include bg_pos;
    background-image: url("./../img/line_bg3.svg");
    .frame_box{
        &:before,&:after{
            display: none;
        }
    }
    @media only screen and (max-width: 1500px) {
        padding: 80px 0;
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 991px) {
        padding: 50px 0;
        .map_holder{
            margin-bottom: 40px;
            text-align: center;
        }
    }
    .desc_with_header{
        margin-bottom: 40px;
    }
}

.scroll_box{
    padding: 40px;
    background: #002652;
    .list_box{
        max-height: 265px;
        overflow-y: scroll;
        .default_font{
            > ul{
                color: $color_white !important;
                > li{
                    color: $color_white !important;
                    *{
                        color: $color_white !important;
                    }
                }
            }
        }
        .default_font{
            > ul{
                > li{
                    margin-bottom: 15px;
                }
            }
        }
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(255,255,255,0.3);
        }

        &::-webkit-scrollbar-thumb {
            background: $color1;
        }

    }
    .info_box{
        padding-top: 30px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        .default_font{
            padding-left: 34px;
            background-repeat: no-repeat;
            background-position: left top;
            background-image: url("./../img/i_icon.svg");
        }
    }
    .default_font{
        color: $color_white;
        li{
            color: $color_white;
        }
    }
}

.custom_popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: rgba(0,0,0,0.4);
    display: none;
    perspective: 2000px;
    &.desc_popup{
        display: block;
    }
    &.popup_active{
        .custom_popup_content{
            transform: rotateY(0deg);
            opacity: 1;
        }
    }
    .custom_popup_holder{
        @include fill;
        @include vertical;

    }
    .custom_popup_content{
        background: $color_white;
        padding: 80px;
        position: relative;
        text-align: center;
        transform-style: preserve-3d;
        //transform: rotateY(-60deg);
        //opacity: 0;
        @include anim;
        .close_popup{
            position: absolute;
            top: 30px;
            right: 30px;
        }
        .title{
            margin-bottom: 20px;
            background-repeat: no-repeat;
            background-position: center top;
            padding-top: 90px;
            background-image: url("./../img/check_icon_popup.svg");
        }
        .desc{
            .default_font{
                margin-bottom: 57px;
            }
        }
    }
}

.service{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
    }
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 65.787%;
        clip-path: polygon(0 50px, 50px 0, 100% 0, 100% 100%, 0 100%);
        @include bg_pos;
        background-size: cover;
        @include default_image;
        @media only screen and (max-width: 767px) {
            clip-path: none;
            margin-bottom: 30px;
        }
    }
    .default_font{
        &.large{
            padding-bottom: 60px;
            border-bottom: 1px solid #EFF1F5;
            @media only screen and (max-width: 767px) {
                padding-bottom: 40px;
            }
        }
    }
}

.q_banner{
    min-height: 640px;
    margin-bottom: 60px;
    @media only screen and (max-width: 1500px) {
        min-height: 540px;
    }
    @media only screen and (max-width: 1200px) {
        min-height: 480px;
    }
    @media only screen and (max-width: 767px) {
        min-height: 630px;
        h2{
            line-height: 34px;
        }
    }
    .container{
        position: relative;
        z-index: 2;
        .default_font{
            color: $color_white;
            *{
                color: inherit;
            }
        }
    }
    .image{
        @include fill;
        @include bg_pos;
        background-size: cover;
        .cover{
            @include fill;
            background: rgba(0,0,0,0.5);
        }
    }
    @include vertical;
    .default_font{
        padding: 60px 0;
    }
}

.transportation{
    .inner_frame_box{
        margin-bottom: 0;
    }
    .offer_form_box {
        .row{
            &:first-child{
                margin-bottom: 32px;
                @media only screen and (max-width: 767px) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .form_content{
        border-bottom: 2px solid #EFF1F5;
        padding-bottom: 32px;
        margin-bottom: 32px;
        &:last-child{
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
    .container{
        > .row{
            margin-bottom: 80px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 50px;
            }
            @media only screen and (max-width: 767px) {
                margin-bottom: 40px;
            }
        }
    }
    .image{
        @include bg_pos;
        background-size: cover;
        @include default_image;
        width: 100%;
        height: 0;
        padding-bottom: 43.226%;
        @media only screen and (max-width: 767px) {
            padding-bottom: 61%;
        }
    }
}

.renovation{
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
    .inner_frame_box{
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
    }
    .renovation_desc{
        padding-right: 50px;
        display: flex;
        height: 780px;
        margin-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 991px) {
            height: auto;
            flex-flow: column;
            padding-left: 15px !important;
            padding-right: 15px !important;
            margin-bottom: 0;
        }
        .desc_side{
            width: 28%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                align-items: stretch;
                order: 2;
            }
            @include vertical;
            > div{
                width: 100%;
                .desc{
                    .large{
                        margin-bottom: 30px;
                    }
                    padding: 54px 80px 54px 0;
                    z-index: 2;
                    position: relative;
                    width: calc(100% + 150px);
                    background: $color_white;
                    @media only screen and (max-width: 1500px) {
                        padding: 30px 50px 30px 0;
                    }
                    @media only screen and (max-width: 991px) {
                        width: calc(100% - 15px);
                        margin-top: -60px;
                        padding: 30px;
                    }
                }
            }
        }
        .image_side{
            width: 72%;
            position: relative;
            @media only screen and (max-width: 991px) {
                width: 100%;
                align-items: stretch;
                order: 1;
                margin-left: -15px;
                width: calc(100% + 30px);
            }
            .image{
                @include fill;
                @include bg_pos;
                background-size: cover;
                @include default_image;
                @media only screen and (max-width: 991px) {
                    position: relative;
                    height: 0;
                    padding-bottom: 80%;
                }
            }
        }
    }
}

.fast_links_content_desc{
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    section.contact_fast_links{
        background: $color3;
        margin-bottom: 40px;
        .fast_links{
            margin-bottom: 0;
        }
    }
}

.fast_links{
    background: $color3;
    margin-bottom: 60px;
    text-align: center;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 40px;
    }
    @media only screen and (max-width: 767px) {
        text-align: left;
        &.frame_box{
            padding: 20px 15px 28px 15px;
           .fast_links_content{
               > .default_font{
                   @include content_width;
                   margin-right: 0;
                   width: 100%;
                   margin-bottom: 30px;
                   p{
                       margin-bottom: 0;
                   }
               }
           }
        }
        .container{
            padding: 0;
        }
    }
    &.contact_fast_links{
        background: $color_white;
        border: 1px solid #eff1f5;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
        .container{
            width: 100%;
        }
        @media only screen and (max-width: 767px) {
            border: 0;
            box-shadow: none;
            background: transparent;
            padding: 30px 0 21px 0;
        }
    }
    .fast_links_content{
        > div{
            display: inline-block;
            vertical-align: middle;
            margin-right: 40px;
            &.large{
                color: $color_font1;
                p{
                    margin-bottom: 0;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .fast_links_nav{
            &.moving_line_nav{
                position: relative;
                .moving_line{
                    left: 0%;
                    @include anim;
                    border-bottom: 2px solid $color1;
                    position: absolute;
                    bottom: 0;
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                @media only screen and (min-width: 992px) {
                    > ul{
                        > li{
                            .toggle_btn{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                white-space: nowrap;
                overflow-x: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                .container{
                    padding: 0;
                }
            }
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 767px) {
                        margin-right: 30px;
                        white-space: normal;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    &.on{
                       a, .toggle_btn{
                           font-weight: 600;
                           color: $color1;
                           &:after{
                               left: 0;
                               width: 100%;
                           }
                       }
                    }
                    div{
                        &.on{
                            a, .toggle_btn{
                                font-weight: 600;
                                color: $color1;
                                &:after{
                                    left: 0;
                                    width: 100%;
                                }
                            }
                        }
                    }
                     a, .toggle_btn{
                        display: inline-block;
                        font-size: 17px;
                        color: $color_font2;
                        padding: 28px 40px;
                        position: relative;
                        font-weight: 400;
                        @include anim;
                        @media only screen and (max-width: 1500px) {
                            padding: 20px 30px;
                        }
                        @media only screen and (max-width: 991px) {
                            padding: 20px 15px;
                            font-size: 15px;
                        }
                        @media only screen and (max-width: 767px) {
                            padding: 0 0 5px 0;
                            line-height: 18px;
                        }
                        &:after{
                            content: '';
                            left: 50%;
                            width: 0%;
                            @include anim;
                            border-bottom: 2px solid $color1;
                            position: absolute;
                            bottom: 0;
                        }
                        &:hover{
                            text-decoration: none;
                            &:after{
                                left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.realizations{
    margin-bottom: 30px;
    .image{
        width: 100%;
        height: 0;
        padding-bottom: 73.48%;
        @include bg_pos;
        background-size: cover;
        @include default_image;
        @include anim;
        &:hover{
            transform: scale(0.97);
        }
    }
}

.fast_links_desc_content{
    > .row{
        margin-bottom: 30px;
        &:first-child{
            margin-bottom: 60px;
        }
    }
}

.services{
    .container{
        > .row{
            margin-bottom: 80px;
            @media only screen and (max-width: 1500px) {
                margin-bottom: 50px;
            }
            @media only screen and (max-width: 767px) {
                margin-bottom: 40px;
            }
            &:first-child{
                margin-bottom: 0;
            }
        }
    }
    .image{
        @include bg_pos;
        @include default_image;
        background-size: cover;
        width: 100%;
        height: 0;
        padding-bottom: 43.226%;
        @media only screen and (max-width: 767px) {
            margin-top: 50px;
            padding-bottom: 61%;
        }
    }
}

.inner_frame_box{
    .default_font{
        &.large{
            h1,h2,h3,h4,h5,h6{
                font-size: 24px;
                line-height: 30px;
                @media only screen and (max-width: 1200px) {
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }
}

.tabor{
    .inner_frame_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
    }
    padding-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 20px;
    }
}

.license{
    .container{
        > .row{
            margin-bottom: 60px;
            &:first-child{
                margin-bottom: 0;
            }
            @media only screen and (max-width: 1500px) {
                margin-bottom: 40px;
            }
        }
    }
}

.license_list{
    > ul{
        > li{
            margin-bottom: 20px;
        }
    }
}

.license_box{
    @include vertical;
    min-height: 200px;
    background: $color3;
    padding: 2px;
    @include anim;
    &.no_btn{
        > div{
            &.license_box_left{
                width: 100%;
                padding-right: 80px;
                @media only screen and (max-width: 991px) {
                    padding-right: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        display: block;
        background: transparent;
        padding: 0;
    }
    &:hover{
        transform: scale(0.97);
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
    }
    &.no_image{
        > div{
            &.license_box_left{
                > div{
                    &.desc{
                        width: 100%;
                    }
                }
            }
        }
    }
    > div{
        &.license_box_left{
            width: calc(100% - 355px);
            @media only screen and (max-width: 991px) {
                width: calc(100% - 200px);
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            > div{
                display: inline-block;
                vertical-align: middle;
                &.image{
                    height: 200px;
                    width: 160px;
                    background-color: $color_white;
                    @include bg_pos;
                    @include default_image;
                    background-color: $color_white;
                    background-size: contain;
                    clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 30px, 100% 100%, 0 100%);
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        height: 0;
                        padding-bottom: 66%;
                    }
                    @media only screen and (max-width: 767px) {
                        background-color: $color3;
                    }
                }
                &.desc{
                    padding-left: 80px;
                    width: calc(100% - 160px);
                    @media only screen and (max-width: 991px) {
                        padding-left: 20px;
                        padding-right: 10px;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        padding: 30px 30px 0 30px;
                    }
                }
            }
        }
        &.license_box_right{
            width: 355px;
            @include vertical;
            @media only screen and (max-width: 991px) {
                width: 200px;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                padding: 30px;
                .btn1{
                    width: 100%;
                }
            }
            > div{
                width: 100%;
                text-align: center;
            }
        }
    }
}

.search_box{
    padding: 14px 30px 16px 0px;
    border: 1px solid #eff1f5;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    @media only screen and (max-width: 767px) {
        padding: 14px 20px 16px 0px;
    }
    > div{
        display: inline-block;
        vertical-align: middle;
        input{
            border: 0;
        }
        &:first-child{
            width: calc(100% - 50px);
            padding: 0 30px;
            input{
                height: 50px;
                color: $color_font2;
                width: 100%;
                font-size: 17px;
            }
        }
        &:last-child{
            width: 50px;
            input{
                font-size: 0;
                background-color: #F7F8FA;
                width: 100%;
                height: 50px;
                border-radius: 50%;
                @include bg_pos;
                background-image: url("./../img/search_icon.svg");
            }
        }
    }
}


.recruitment{
    padding-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
    }
    .inner_frame_box{
        margin-bottom: 0;
    }
    .inner_frame_box{
        padding-bottom: 50px;
        @media only screen and (max-width: 767px) {
            padding-bottom: 30px;
        }
    }
}

.recruitment_contact{
    &.white{
        margin-right: -80px;
        @media only screen and (max-width: 1500px) {
            margin-right: 0;
        }
        .default_font{
            .lead_desc{
                color: $color_white;
                border-left: 2px solid rgba(255,255,255,0.2);
            }
            span{
                background-image: url("./../img/phone2.svg");
                a{
                    color: $color_white;
                }
            }
        }
    }
    .lead_desc{
        padding: 25px 0 25px 40px;
        border-left: 2px solid $color3;
        display: inline-block;
        span{
            margin-left: 30px;
            font-size: 17px;
            line-height: 23px;
            font-weight: 400;
            padding-left: 36px;
            background-repeat: no-repeat;
            background-position: left center;

            background-image: url("./../img/phone.svg");
            a{
                color: $color_font1;
                text-decoration: none;
                @include anim;
                &:hover{
                    color: $color1;
                }
            }
        }
    }
}

.filters{
    @media only screen and (max-width: 991px) {
        label{
            display: block;
        }
        .inline_bottom{
            padding: 30px 0;
            background: $color3;
            select{
                display: block;
                width: 100% !important;
            }
            > div{
                width: 100%;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .filters_submit{
        background-color: $color_white;
        border: 2px solid #eff1f5;
        text-align: center;
        background-repeat: no-repeat;
        background-position: right 105px center;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        color: $color_font2;
        width: 100%;
        height: 55px;
        background-image: url("./../img/submit_arr.svg");
        padding-top: 2px;
        padding-right: 25px;
    }
    .row{
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
}

.filters_result_list{
    > ul{
        > li{
            margin-bottom: 10px;
            &:hover{
                a{
                    text-decoration: none;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.recruitment_box{
    background: $color3;
    //padding: 40px 80px;
    @include content_width;
    color: $color_font1;
    @include anim;
    //@media only screen and (max-width: 1500px) {
    //    padding: 40px 50px;
    //}
    padding: 15px 30px;
    @media only screen and (max-width: 767px) {
        display: block;
        padding: 30px;
    }
    .btn1{
        padding: 10px 66px 10px 40px;
        background-position: right 40px center;
        @media only screen and (max-width: 767px) {
            //margin-top: 30px;
            margin-top: 20px;
        }
    }
    &:hover{
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
        transform: scale(0.97);
    }
    .section_header{
        //margin-bottom: 20px;
        margin-bottom: 0;
        h3{
            font-size: 17px;
            line-height: 23px;
        }
    }
}

.default_font{
    &.adress{
        padding-left: 27px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("./../img/blue_pin.svg");
        &.white{
            color: $color_white;
            background-image: url("./../img/white_pin.svg");
        }
    }
}

.recruitment_details{
    .section_header{
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            h2{
                font-size: 24px;
            }
        }
    }
    .inner_frame_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 40px;
        }
    }
}

.recruitment_details_base_info{
    background-color: $color3;
    padding-top: 80px;
    padding-bottom: 160px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 120px;
        padding-top: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        .col-sm-3{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    h4{
        font-size: 20px;
        line-height: 21px;
        color: $color1;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
            font-size: 17px;
            line-height: 23px;
        }
    }
}

.recruitment_desc{
    margin-top: -80px;
    background-color: $color_white;
    padding: 80px 106px;
    position: relative;
    z-index: 2;
    border: 1px solid #eff1f5;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.05));
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
        padding: 80px;
    }
    @media only screen and (max-width: 991px) {
        padding: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0;
        padding: 0;
        border: 0;
        filter: none;
        .text-center{
            text-align: left;
        }
    }
    .recruitment_desc_row{
        padding-bottom: 60px;
        margin-bottom: 60px;
        border-bottom: 2px solid #EFF1F5;
        @media only screen and (max-width: 767px) {
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    .recruitment_lead{
        margin-bottom: 72px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
        h3{
            margin-bottom: 20px;
            @media only screen and (max-width: 767px) {
                margin-bottom: 10px;
            }
        }
    }
    .recruitment_desc_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
        .recruitment_desc_box_title{
            margin-bottom: 30px;
            .default_font{
                &.large{
                    color: $color_font1;
                }
            }
        }
    }
    .application_banner{
        padding: 72px 80px;
        background: $color3;
        @media only screen and (max-width: 1500px) {
            padding: 60px 50px;
        }
        @media only screen and (max-width: 991px) {
            padding: 30px;
        }
        @media only screen and (max-width: 767px) {
            padding: 40px 30px;
            text-align: left;
        }
        .text-right{
            position: relative;
            z-index: 2;
            @media only screen and (max-width: 767px) {
                text-align: left;
                padding-top: 30px;
                .btn1{
                    font-size: 12px;
                }
            }
        }
    }
}

.why_pkp{
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    .inner_frame_box{
        margin-bottom: 0;
    }
}

.step_box{
    .step{
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 38px;
        font-weight: 600;
        margin: 0 auto 30px auto;
        border-radius: 50%;
        background-color: $color1;
        color: $color_white;
        text-align: center;
    }
    .default_font{
        text-align: center;
        color: $color_white;
    }
}

.about_work{
    padding-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        padding-bottom: 20px;
    }
    .inner_frame_box{
        margin-bottom: 60px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
}

.practice{
    &.practice_bottom{
        @media only screen and (max-width: 1500px) {
            margin-bottom: 50px;
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
    }
    @media only screen and (max-width: 991px) {
        .container{
            > .row{
                &:last-child{
                    margin-bottom: 80px;
                    @media only screen and (max-width: 1500px) {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
    .default_font{
        &.large{
            //margin-bottom: 80px;
            //@media only screen and (max-width: 1500px) {
            //    margin-bottom: 0;
            //}
        }
    }
}

.map_contact_data{
    background: $color_white;
    padding: 40px;
    border-left: 2px solid $color1;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
    margin-bottom: 60px;
    position: relative;
    display: none;
    @media only screen and (max-width: 767px) {
        border-top: 1px solid #EAEAEA;
        border-right: 1px solid #EAEAEA;
        border-bottom: 1px solid #EAEAEA;
        padding: 40px 30px;
        margin-bottom: 40px;
        .contact_list{
            > ul{
                > li{
                    &.default_font{
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    .close_contact{
        position: absolute;
        right: 20px;
        top: 20px;
        //@media only screen and (max-width: 767px) {
        //    top: 10px;
        //    right: 10px;
        //}
    }
    .large{
        margin-bottom: 30px;
        color: $color_font1;
    }
}

.contact{
    margin-bottom: 80px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 0;
    }
    .areas_contact_data{
        padding-right: 15%;
        @media only screen and (max-width: 767px) {
            padding-right: 0;
        }
    }
    .map_info{
        margin-top: -10px;
        padding-top: 40px;
        border-top: 1px solid #EFF1F5;
        @media only screen and (max-width: 767px) {
            margin-top: 0;
            border-top: 0;
            .default_font{
                margin-bottom: 30px;
            }
        }
        > .default_font{
            padding-left: 33px;
            background-repeat: no-repeat;
            background-position: left top;
            background-image: url("./../img/contact_i_icon.svg");
        }
    }
    .section_header{
        h2{
            padding-bottom: 30px;
            @media only screen and (max-width: 1500px) {
                padding-bottom: 0;
            }
            @media only screen and (max-width: 767px) {
                padding-bottom: 20px;
                font-size: 24px;
            }
        }
        h3{
            padding-bottom: 10px;
        }
    }
}

.contact_fast_links{
    margin-top: -20px;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 1500px) {
        margin-top: -18px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0;
    }
}

.contact_content{
    padding: 80px 0;
    position: relative;
    @media only screen and (max-width: 1500px) {
        padding: 40px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 0;
        .section_header{
            h3{
                font-size: 20px;
            }
        }
    }
    &.contact_content2{
        @media only screen and (max-width: 767px) {
            .inline_row{
                > div{
                    width: 100%;
                }
            }
        }
        &:after{
            right: calc(50% - 410px);
        }
    }
    .contact_data_split{
        > div{
            display: inline-block;
            vertical-align: top;
            width: 50%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                margin-bottom: 40px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .desc{
                .title{
                    h3{
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 5px;
                    }
                }
                .default_font{
                    margin-bottom: 20px;
                }
            }
        }
    }
    &.with_map{
        padding-right: 50px;
        @media only screen and (max-width: 767px) {
            padding-right: 15px;
        }
        > div{
            position: relative;
            z-index: 2;
            display: inline-block;
            vertical-align: top;
            &.contact_data_side{
                width: 45%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
            &.contact_data_map_side{
                width: 55%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
    &:after{
        content: '';
        top: 0;
        left: 50px;
        right: calc(50% - 80px);
        background: $color3;
        position: absolute;
        height: 100%;
        @media only screen and (max-width: 1500px) {
            left: 30px;
        }
        @media only screen and (max-width: 1200px) {
            left: 15px;
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
    .container{
        position: relative;
        z-index: 2;
    }
    .contact_data_map_side{
        .map_box_contact{
            position: relative;
            width: 100%;
            height: 0;
            //padding-bottom: 38.211%;
            padding-bottom: 393px;
            @media only screen and (max-width: 767px) {
                width: calc(100% + 15px);
                padding-bottom: 320px;
            }
            iframe{
                @include fill;
                border: 0;
            }
        }
    }
}

.media_banner{
    padding: 40px 80px;
    background-color: $color1;
    color: $color_white;
    background-repeat: no-repeat;
    background-position: left 25px center;
    background-image: url("./../img/media_bg.svg");
    @media only screen and (max-width: 1500px) {
        padding: 40px;
    }
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
        padding: 40px 30px;
        width: 100%;
        margin-top: 40px;
        background-position: left 23px top 15px;
        .inline_row{
            display: flex;
            flex-flow: column;
            > div{
                width: 100%;
                align-items: stretch;
                &.col-sm-9{
                    order: 2;
                }
                &.col-sm-3{
                    order: 1;
                }
                width: 100%;
            }
        }
    }
    .banner_content{
        @media only screen and (max-width: 767px) {
            margin-top: -35px;
        }
        .col-sm-7{
            .default_font{
                padding-left: 10px;
                @media only screen and (max-width: 767px) {
                    padding-left: 0;
                    padding-top: 20px;
                }
            }
        }
    }
    .default_font{
        color: $color_white;
        line-height: 24px;
        li{
            &:before{
                background: $color_white;
            }
        }
        p,li{
            margin-bottom: 0;
        }
        h4{
            color: inherit;
        }
    }
    a{
        text-align: center;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 50px;
        background: rgba(255,255,255,0.1);
    }
}

//.contact_map_box{
//    position: relative;
//    &:hover{
//        .map_tooltip{
//            opacity: 1;
//            visibility: visible;
//        }
//    }
//}

.map_tooltip{
    @include anim;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    font-size: 13px;
    line-height: 20px;
    color: $color_font1;
    display: inline-block;
    padding: 10px 18px;
    background: $color_white;
    transform: translate(calc(-50% + 22px), -60px);
    white-space: nowrap;
    text-align: center;
    &:after{
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 18px;
        height: 8px;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        background: $color_white;
    }
    p{
        font-weight: 600;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.fast_links_toggles{
    @media only screen and (max-width: 767px) {
        .gallery, .realizations{
            margin-bottom: 10px;
        }
        .element_list{
            > ul{
                > li{
                    width: 50%;
                }
            }
        }
    }
    > div{
        > div{
            display: none;
            perspective: 2000px;
            &.toggle_active{
                .row{
                    opacity: 1;
                    transform: rotateY(0deg);
                }
            }
            .row{
                @include anim;
                opacity: 0;
                transform-style: preserve-3d;
                transform: rotateY(30deg);
            }
        }
    }
}

.text-right{
    position: relative;
    z-index: 2;
}

.mobile_area_list{
    margin-bottom: 30px;
    .select2-container--default {
        text-align: left;
        .select2-selection--single {
            border: none;
            height: 80px;
            border-radius: 0;
            background: $color1;
            color: $color_white;
            font-size: 15px;
            font-weight: 600;
            .select2-selection__rendered{
                line-height: 80px;
                padding: 0 30px;
                padding-right: 90px;
                color: $color_white;
            }
            .select2-selection__arrow{
                width: 80px;
                height: 100%;
                top: 0;
                right: 0;
                background-repeat: no-repeat;
                background-image: url("./../img/mobile_select_arr.svg");
                background-position: right 30px center;
                b{
                    display: none;
                }
            }
        }
        .select2-search--dropdown {
            .select2-search__field{
                background: $color1;
                color: $color_font1;
                border: none;
                border-bottom: 2px solid #e0e5e8;

                height: 36px;
            }
        }
    }

    .select2-results__option{
        &.select2-results__option--highlighted{
            background-color: $color2 !important;
        }
    }

    .select2-dropdown{
        border: 1px solid #e0e5e8;
        border-top: none;
    }

    .select2-container--default .select2-results__option[aria-selected=true]{
        background-color: #F8F8F8;
    }

    .select2-results{
        padding: 0 4px;
    }

}

@media only screen and (max-width: 767px) {
    .side_left, .side_right{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.filters_show_btn{
    background: $color3;
    color: $color_black;
    display: inline-block;
    padding: 0px 70px 0px 40px;
    font-size: 14px;
    line-height: 55px;
    background-repeat: no-repeat;
    background-position: right 40px center;
    background-image: url("./../img/date_toggle.svg");
    font-weight: 600;
    text-transform: uppercase;
}

.a_box{
    position: fixed;
    right: -100px;
    top: calc(50% - 80px);
    z-index: 10;
    @include anim;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    &.tools_visible{
        right: 0;
    }
    @media only screen and (max-width: 767px) {
        top: calc(50% - 40px);
    }
    .a_btn{
        margin-right: -1px;
        border: 1px solid #e5e5e5;
        border-right: 0;
       // box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
        box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.1);
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: $color_white;
        position: relative;
        z-index: 2;
        @media only screen and (max-width: 767px) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            img{
                max-width: 20px;
            }
        }
    }
    .a_btn, .a_box_content{
        display: inline-block;
        vertical-align: top;
    }
    .a_box_content{
        border-bottom-left-radius: 3px;
        width: 100px;
        height: 235px;
        background: $color_white;
        border: 1px solid #e5e5e5;
        box-shadow: -3px 5px 5px rgba(0, 0, 0, 0.1);
        position: relative;
        img{
            background-color: $color_white;
            width: 40px;
            border-radius: 50%;
        }
        .a_box_content_holder{
            width: 100%;
            height: 100%;
            display: none;
            > div{
                @include fill;
                @include vertical;
                > div{
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .plugin_font_sizes{
            padding-top: 15px;
            > li{
                display: block;
                margin-right: 0;
                line-height: normal !important;
            }
        }
    }
}
.err_txt {
    color:$color_err;
}

.map_box {
    &.offer_map_box_section{
        padding: 80px 0;
        background-color: $color2;
        background-image: url("./../img/line_bg3.svg");
        @include bg_pos;
    }
    > svg{
        height: 528px;
        width: 100%;
    }
    svg {
        svg{
            path{
                cursor: pointer;
            }
        }
        g {
            path {
                fill: #005BAB;
                stroke: #4B8AD1;
                &.active {
                    fill: #1C6FB6;
                    stroke: #1C6FB6;
                }
            }
        }
    }

    //.point {
    //    display: none;
    //    position:absolute;
    //    left:100px;
    //    top:100px;
    //    width:14px;
    //    height:14px;
    //    background-color:#1C6FB6;
    //    border-radius: 50%;
    //    border:3px solid #000;
    //    margin-left:-8px;
    //    margin-top:-8px;
    //}
}

.underconstruction_page{
    padding: 50px;
    background: $color_white;
    @media only screen and (max-width: 991px) {
        padding: 30px;
    }
    @media only screen and (max-width: 767px) {
        padding: 15px;
    }
    .underconstruction_page_holder{
        background-color: $color3;
        width: 100%;
        height: calc(100vh - 100px);
        position: relative;
        @include vertical;
        border-radius: 25px;
        @include bg_pos;
        background-size: cover;
        background-image: url("./../img/bg_lines.svg");
        @media only screen and (max-width: 991px) {
            height: calc(100vh - 60px);
        }
        @media only screen and (max-width: 767px) {
            height: calc(100vh - 30px);
        }
        .underconstruction_page_content{
            background: $color_white;
            padding: 80px 30px 60px 30px;
            border-radius: 25px;
            box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.05);
            @media only screen and (max-width: 767px) {
                padding: 50px 15px 30px 15px;
            }
        }
        > div{
            width: 100%;
            .logo_box{
                margin-bottom: 30px;
                @media only screen and (max-width: 767px) {
                    margin-bottom: 15px;
                }
            }
            .default_font{
                text-transform: uppercase;
                h1{
                    color: $color_font1;
                    @media only screen and (max-width: 767px) {
                        font-size: 24px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}

.error_page{
    height: 700px;
    background: $color3;
    @include vertical;
    @include bg_pos;
    background-size: cover;
    background-image: url("./../img/bg_lines.svg");
    margin-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        margin-bottom: 30px;
        height: 500px;
    }
    @media only screen and (max-width: 1200px) {
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 0;
        height: 300px;
    }
    .error_content{
        text-align: center;
        h1{
            color: $color2;
            font-size: 120px;
            line-height: 120px;
            margin-bottom: 20px;
            @media only screen and (max-width: 991px) {
                font-size: 60px;
                line-height: 60px;
                margin-bottom: 10px;
            }
        }
    }
}

.desc_popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
    .desc_popup_holder{
        @include fill;

        overflow-y: scroll;
        /* Handle */
        @media only screen and (max-width: 991px) {
            display: block;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: $color1;
        }
        .desc_popup_content{
            border-radius: 20px;
            background: $color_white;
            padding: 50px;
            border: 1px solid $color3;
            box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.15);
            position: relative;
            margin-top: 50px;
            margin-bottom: 50px;
            @media only screen and (max-width: 767px) {
                padding: 20px;
                padding-top: 50px;
                border-radius: 10px;
            }
            .close_popup{
                position: absolute;
                top: 15px;
                right: 20px;
            }
        }
    }
}

.sr-only{
    color: $color_black;
}

.btn1{
    .sr-only{
        color: $color_white;
    }
}

.skiplinks{
    position: absolute;
    left: 0;
    top: 0;
    ul{
        li{
            a{
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;
                &:focus{
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }
    a{
        background: $color_black;
        color: $color_white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

[data-whatintent='mouse'] *:focus {
    outline: none;
}

.lb-cancel{
    background-size: cover;
}
